import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MessagePreview from '../../components/Templates/MessagePreview'; // Import the MessagePreview component
import axios from 'axios';
import './PreviewRun.css';

const PreviewRun = ({ open, onClose, onConfirm, recipients, onRemoveRecipient, campaign, organization }) => {
    const [search, setSearch] = useState("");
    const [templatePreview, setTemplatePreview] = useState(null);
    const [regularMessage, setRegularMessage] = useState("");

    const displayedRecipients = search
        ? recipients.filter(recipient =>
            recipient.name.toLowerCase().includes(search.toLowerCase()) ||
            recipient.phoneNumber.includes(search)
        )
        : recipients;

    useEffect(() => {
        if (!campaign) return; // If campaign is null, exit early

        if (campaign.messageType === 'Regular') {
            // Set the regular message directly from the campaign data
            setRegularMessage(campaign.message);
        } else if (campaign.messageType === 'Template' && campaign.wabaTemplateId) {
            // Fetch template preview using wabaTemplateId with a POST request
            axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById', {
                organizationId: organization,
                templateId: campaign.wabaTemplateId
            })
            .then(response => {
                const data = response.data;
                if (data) {
                    // Parse the components to get BODY, FOOTER, and BUTTONS data
                    const bodyComponent = data.components.find(component => component.type === "BODY");
                    const footerComponent = data.components.find(component => component.type === "FOOTER");
                    const buttonsComponent = data.components.find(component => component.type === "BUTTONS");

                    setTemplatePreview({
                        headerType: null, // No header in the provided example
                        mediaType: null,   // Assuming no media type based on the example
                        mediaContent: null,
                        mediaUrl: data.gmbtMdiaId || "",
                        variables: bodyComponent?.example?.body_text[0] || [],
                        footerText: footerComponent?.text || "",
                        variableSamples: {}, // No sample values provided in example
                        bodyContent: bodyComponent?.text || "",
                        buttons: buttonsComponent?.buttons || [],
                        headerTxt: data.name || ""
                    });
                }
            })
            .catch(error => console.error("Error fetching template preview:", error));
        }
    }, [campaign, organization]);

    return (
        <div className='previewRun-Container'>
            <Dialog className='previewRun-Container-dialog' open={open} onClose={onClose}>
                <DialogTitle className='previewRun-Container-dialog-title'>Confirm Message & Recipients</DialogTitle>
                
                {/* Display the message preview based on the message type */}
                {campaign?.messageType === 'Regular' && regularMessage && (
                    <div className="regular-message-preview">
                        <h3>Message Preview</h3>
                        <p>{regularMessage}</p>
                    </div>
                )}

                {campaign?.messageType === 'Template' && templatePreview && (
                    <div className="template-preview-container">
                        <MessagePreview
                            headerType={templatePreview.headerType}
                            mediaType={templatePreview.mediaType}
                            mediaContent={templatePreview.mediaContent}
                            mediaUrl={templatePreview.mediaUrl}
                            variables={templatePreview.variables}
                            footerText={templatePreview.footerText}
                            variableSamples={templatePreview.variableSamples}
                            bodyContent={templatePreview.bodyContent}
                            buttons={templatePreview.buttons}
                            headerTxt={templatePreview.headerTxt}
                        />
                    </div>
                )}

                <DialogContent>
                    <TextField
                        label="Search by Name or Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Table className='previewRun-Container-dialog-tbl'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Recipient Name</TableCell>
                                <TableCell>Recipient Phone</TableCell>
                                <TableCell></TableCell> {/* Column for the delete button */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayedRecipients.map((recipient, index) => (
                                <TableRow key={index} className="previewRun-Container-row">
                                    <TableCell>{recipient.name}</TableCell>
                                    <TableCell>{recipient.phoneNumber}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => onRemoveRecipient(recipient.id)}
                                            size="small"
                                            className="previewRun-Container-deleteButton"
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onConfirm} color="primary">Send</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PreviewRun;


// import React, { useState } from 'react';
// import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableRow, TableCell, TableBody, TextField, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import './PreviewRun.css';

// const PreviewRun = ({ open, onClose, onConfirm, recipients, onRemoveRecipient }) => {
//     const [search, setSearch] = useState("");

//     const displayedRecipients = search
//         ? recipients.filter(recipient =>
//             recipient.name.toLowerCase().includes(search.toLowerCase()) ||
//             recipient.phoneNumber.includes(search)
//         )
//         : recipients;

//     return (
//         <div className='previewRun-Container'>
//             <Dialog className='previewRun-Container-dialog' open={open} onClose={onClose}>
//                 <DialogTitle className='previewRun-Container-dialog-title'>Confirm Recipients</DialogTitle>
                
//                 {/* New text string */}
//                 <div className='previewRun-Container-Template-Example'>
//                     <p>Please review the recipients carefully before sending.</p>
//                 </div>

//                 <DialogContent>
//                     <TextField
//                         label="Search by Name or Phone Number"
//                         variant="outlined"
//                         fullWidth
//                         margin="normal"
//                         value={search}
//                         onChange={(e) => setSearch(e.target.value)}
//                     />
//                     <Table className='previewRun-Container-dialog-tbl'>
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell>Recipient Name</TableCell>
//                                 <TableCell>Recipient Phone</TableCell>
//                                 <TableCell></TableCell> {/* Column for the delete button */}
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {displayedRecipients.map((recipient, index) => (
//                                 <TableRow key={index} className="previewRun-Container-row">
//                                     <TableCell>{recipient.name}</TableCell>
//                                     <TableCell>{recipient.phoneNumber}</TableCell>
//                                     <TableCell align="right">
//                                         <IconButton
//                                             onClick={() => onRemoveRecipient(recipient.id)}
//                                             size="small"
//                                             className="previewRun-Container-deleteButton"
//                                         >
//                                             <CloseIcon fontSize="small" />
//                                         </IconButton>
//                                     </TableCell>
//                                 </TableRow>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={onClose}>Cancel</Button>
//                     <Button onClick={onConfirm} color="primary">Send</Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default PreviewRun;
