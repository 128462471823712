import React, { useEffect, useRef, useState, useCallback } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertEmoticon from '@mui/icons-material/InsertEmoticon';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Picker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import StartConversationByTemplate from './Templates/startConversationByTemplate';
import './ChatContainer.css';
import axios from 'axios';

const ChatContainer = ({ currentUser, setCurrentChatRecipient, webSocket, selectedContact }) => {
    const [message, setMessage] = useState('');
    const [openEmojiBox, setOpenEmojiBox] = useState(false);
    const { contactphonenumber } = useParams();
    const [chatMessages, setChatMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const chatBox = useRef(null);
    const [IsConversationLiveByPhoneNumber, setIsConversationLiveByPhoneNumber] = useState(false);
    const [isRecipientReplyLast24Hours, setisRecipientReplyLast24Hours] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [users, setUsers] = useState([]);
    const [currentOwner, setCurrentOwner] = useState(null);
    const [showChangeOwner, setShowChangeOwner] = useState(false);
    const [conversationStatus, setConversationStatus] = useState("Open"); // New state for conversation status
      const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false); // Corrected name
    const [newCategoryName, setNewCategoryName] = useState(""); // Corrected name
    const [newCategoryDetails, setNewCategoryDetails] = useState(""); // To capture additional details if needed
    const [conversation , setConversation] = useState();
    // Fetch categories when the component loads or organization changes
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/getConversationCategories", {
                    organization: currentUser.organization,
                });
                setCategories(response.data || []);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };
        fetchCategories();
    }, [currentUser.organization]);

    const handleCategoryChange = async (event) => {
        const value = event.target.value;
        setSelectedCategory(value);
    
        if (value === "add_new") {
            setShowNewCategoryInput(true); // Show input to add new category
        } else {
            // Update the conversation category in the backend
            try {
                await axios.post("https://gambot.azurewebsites.net/api/Webhooks/updateConversationCategory", {
                    organization: currentUser.organization,
                    phoneNumber: contactphonenumber,
                    category: value,
                    modifiedById : currentUser?.userId
                });
            } catch (error) {
                console.error("Error updating conversation category:", error);
            }
        }
    };

    const handleAddNewCategory = async () => {
        if (!newCategoryName.trim()) return;

        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/addConversationCategory", {
                organization: currentUser.organization,
                label: newCategoryName,
                details: newCategoryDetails,
                createdBy : currentUser?.userId
            });

            // Fetch updated categories after adding the new one
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/getConversationCategories", {
                organization: currentUser.organization,
            });
            setCategories(response.data || []);
            setSelectedCategory(newCategoryName);
            setShowNewCategoryInput(false); // Close input form
            setNewCategoryName(""); // Clear input field
            setNewCategoryDetails("");
        } catch (error) {
            console.error("Error adding category:", error);
        }
    };
    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        setConversationStatus(newStatus);
        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/updateConversationStatus", {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber,
                status: newStatus,
                modifiedById : currentUser?.userId
            });
        } catch (error) {
            console.error("Error updating conversation status:", error);
        }
    };
    const [expirationTime, setExpirationTime] = useState(null); // New state for expiration time
    const [timeRemaining, setTimeRemaining] = useState(null);   // New state for countdown

    // Fetch conversation expiration time
    const fetchExpirationTime = useCallback(async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/getConversationExpirationTime", {
                organization: currentUser.organization,
                phoneNumber: contactphonenumber
            });

            if (response.data) {
                const expirationTimestamp = response.data;
                if (expirationTimestamp) {
                    setExpirationTime(new Date(expirationTimestamp));
                } else {
                    setExpirationTime(null); // No expiration time if empty string
                }
            }
        } catch (error) {
            console.error("Error fetching conversation expiration time:", error);
        }
    }, [currentUser.organization, contactphonenumber]);

    // Start countdown timer when expiration time is set
    useEffect(() => {
        if (!expirationTime) return;

        const interval = setInterval(() => {
            const now = new Date();
            const diff = expirationTime - now;

            if (diff <= 0) {
                clearInterval(interval);
                setTimeRemaining(null);
            } else {
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((diff % (1000 * 60)) / 1000);
                setTimeRemaining(`${hours}:${minutes}:${seconds}`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [expirationTime]);



    const fetchChatMessages = useCallback(async (phoneNumber) => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetMessagesByPhoneNumber", {
                organizationiD: currentUser.organization,
                phoneNumber: phoneNumber,
            });
            const messages = response.data || [];
            setChatMessages(messages);
            setFilteredMessages(messages);
        } catch (error) {
            console.log('Error fetching messages:', error);
        }
    }, [currentUser.organization, contactphonenumber]);

    useEffect(() => {
        if (contactphonenumber) {
            fetchChatMessages(contactphonenumber);
            checkConversationStatus(currentUser.organization, contactphonenumber);
            fetchExpirationTime();
        }
    }, [contactphonenumber, fetchChatMessages,fetchExpirationTime]);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'messages') {
                fetchChatMessages(contactphonenumber);
            }
        };

        if (webSocket) {
            webSocket.addEventListener('message', handleMessage);
        }

        return () => {
            if (webSocket) {
                webSocket.removeEventListener('message', handleMessage);
            }
        };
    }, [contactphonenumber, webSocket, fetchChatMessages]);

    const checkConversationStatus = async (organization, phoneNumber) => {
        try {
            const liveResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/IsConversationLiveByPhoneNumber", { organization, phoneNumber });
            setIsConversationLiveByPhoneNumber(liveResponse.data);

            const replyResponse = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/isRecipientReplyLast24Hours", { organization, phoneNumber });
            setisRecipientReplyLast24Hours(replyResponse.data);

            if(isRecipientReplyLast24Hours){
                
            }
        } catch (error) {
            console.error("Error checking conversation status", error);
        }
    };

    const send = async (e) => {
        e.preventDefault();
        if (contactphonenumber && message.trim() !== '') {
            const messageText = message.replace(/(?:\r\n|\r|\n)/g, '\\n');
            const payload = {
                text: messageText,
                from: currentUser.wabaNumber,
                to: contactphonenumber,
                senderEmail: currentUser.email,
                receiverEmail: contactphonenumber,
                timeStamp: '',
                sentByName: currentUser.fullname,
                sentById: currentUser.userId,
                organization: currentUser.organization
            };

            try {
                await axios.post("https://gambot.azurewebsites.net/api/Webhooks/CreateOutboundMessage", payload);
                setMessage('');
                setCurrentChatRecipient(contactphonenumber);
                fetchChatMessages(contactphonenumber);
            } catch (error) {
                console.log('Error sending message:', error);
            }
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.altKey) {
            event.preventDefault();
            setMessage(message + '\n');
        } else if (event.key === 'Enter' && !event.altKey) {
            event.preventDefault();
            send(event);
        }
    };

    const toggleStar = (messageId, isStarred) => {
        axios.post("https://gambot.azurewebsites.net/api/Webhooks/ToggleStarredMessage", {
            messageId: messageId,
            phoneNumber: contactphonenumber,
            organizationId: currentUser.organization
        }).catch(error => console.error("Error updating star status:", error));
    };

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        filterMessages(term, showStarredOnly);
    };

    const toggleShowStarred = () => {
        setShowStarredOnly(!showStarredOnly);
        filterMessages(searchTerm, !showStarredOnly);
    };

    const filterMessages = (searchTerm, showStarred) => {
        let filtered = chatMessages;
        if (showStarred) {
            filtered = filtered.filter(msg => msg.isStarred);
        }
        if (searchTerm) {
            filtered = filtered.filter(msg =>
                msg.text.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredMessages(filtered);
    };
   

    const fetchCurrentOwner = useCallback(async () => {
        if (selectedContact && selectedContact.ownerId) {
            console.log(users);
            console.log(users[0]?.uID);
            console.log(selectedContact?.ownerId);
            const owner = users.find(user => user.uID === selectedContact.ownerId);
            setCurrentOwner(owner || null);
        }
    }, [ users]);
    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllUsersByOrg", {
                organization: currentUser.organization
            });
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    }, [currentUser.organization]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
        fetchCurrentOwner();
    }, [fetchCurrentOwner, selectedContact]);

    const handleChangeOwner = async (newOwnerId) => {
        try {
            await axios.post("https://gambot.azurewebsites.net/api/Webhooks/UpdateContactOwner", {
                contactPhoneNumber: contactphonenumber,
                ownerId : newOwnerId,
                organization: currentUser.organization
            });
            const newOwner = users?.find(user => user.uID === newOwnerId);
            setCurrentOwner(newOwner);
            setShowChangeOwner(false);
        } catch (error) {
            console.error("Error updating contact owner:", error);
        }
    };
    useEffect(() => {
        setExpirationTime(null);
        setTimeRemaining(null);
        fetchExpirationTime();
    }, [contactphonenumber, selectedContact, fetchExpirationTime]);
    console.log('IsConversationLiveByPhoneNumber', IsConversationLiveByPhoneNumber);
    console.log('isRecipientReplyLast24Hours',isRecipientReplyLast24Hours);
    return (
        <div className='chat-container'>
            <div className='chat-container-header'>
                <div className='chat-user-info'>
                <p>{selectedContact?.name + " " + contactphonenumber || ""}</p>
                
                <div className='chat-user-details'>
                        <p>Owner: {currentOwner ? currentOwner.UserName : "Unassigned"}</p>
                        <button className='change-owner-button' onClick={() => setShowChangeOwner(!showChangeOwner)}>
                        Assign To
                        </button>
                        {showChangeOwner && (
                                <select className='owner-select' onChange={(e) => handleChangeOwner(e.target.value)} value={currentOwner?.uID || ""}>
                                <option value="" disabled>Select new owner</option>
                                {users.map(user => (
                                    <option key={user.uID} value={user.uID}>{user.UserName}</option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div className='chat-expiration-time'>
                    {isRecipientReplyLast24Hours && timeRemaining ? (
                        <div className='countdown-timer'>
                            <span className='time-circle'>{timeRemaining}</span>
                        </div>
                    ) : isRecipientReplyLast24Hours ? (
                        <div className='no-time-remaining'>
                            <span>No active expiration time.</span>
                        </div>
                    ) : null}
                </div>
                <div className="category-select">
                <label>Select Category:</label>
                <select value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                    <option value="add_new">Add New Category</option>
                </select>
            </div>
                <div className='chat-status-selector'>
                        <label htmlFor='status'>Status:</label>
                        <select 
                            id='status' 
                            value={conversationStatus} 
                            onChange={handleStatusChange}
                        >
                            <option value="Open">Open</option>
                            <option value="In Process">In Process</option>
                            <option value="Closed">Closed</option>
                        </select>
                    </div>
                </div>
                <div className='chat-container-controls'>
                    <input className='chat-container-chat-search'
                        type="text"
                        placeholder="Search messages"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <button className='chat-container-starred-messages-header' onClick={toggleShowStarred}>
                        {showStarredOnly ? <StarIcon style={{ color: 'gold' }} /> : <StarBorderIcon />}
                    </button>
                </div>
            </div>

            <div className='chat-display-container' ref={chatBox}>
                {filteredMessages && filteredMessages.length > 0 ? (
                    filteredMessages.map((message) => (
                        <ChatMessage
                            key={message.id}
                            message={message.text}
                            time={message.timeStamp}
                            sender={contactphonenumber}
                            from={message.from}
                            id={message}
                            orgazanizationNumber={currentUser.wabaNumber}
                            userName={message.sentByName}
                            messageType={message.type}
                            isStarred={message.isStarred}
                            onStarToggle={toggleStar}
                            createdOn={message?.createdOn}
                            currentUser={currentUser}
                        />
                    ))
                ) : (
                    <p>No messages</p>
                )}
            </div>

            <div className='chat-input'>
                {IsConversationLiveByPhoneNumber && !isRecipientReplyLast24Hours ? (
                    <p>Wait for user reply</p>
                ) : (isRecipientReplyLast24Hours && IsConversationLiveByPhoneNumber) 
                || (isRecipientReplyLast24Hours && !IsConversationLiveByPhoneNumber) ? (
                        <>
                        {openEmojiBox && <Picker onEmojiClick={(event, emojiObject) => setMessage(message + emojiObject.emoji)} />}
                        <div className='chat-input-btn'>
                            <InsertEmoticon onClick={() => setOpenEmojiBox(!openEmojiBox)} />
                            <AttachFileIcon />
                        </div>
                        <form onSubmit={send}>
                            <textarea
                                placeholder='Type a message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyPress}
                                rows="3"
                            />
                        </form>
                        <div className='chat-input-send-btn' onClick={send}>
                            <SendIcon />
                        </div>
                    </>
                ) : (
                    <button onClick={() => setShowPopup(true)}>Start New Conversation</button>
                )}
            </div>

            {showPopup && (
                <div className='popup'>
                    <div className='popup-inner'>
                        <StartConversationByTemplate 
                            currentUser={currentUser}
                            contactPhoneNumber={contactphonenumber} 
                            onClose={() => setShowPopup(false)} 
                        />
                    </div>
                </div>
            )}

             {/* Popup Form for Adding New Category */}
             {showNewCategoryInput && (
                <div className="chat-new-category-modal">
                    <div className="chat-new-category-modal-content">
                        <h3>Add New Category</h3>
                        <label>
                            Category Name:
                            <input
                                type="text"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                                placeholder="Enter category name"
                            />
                        </label>
                        <label>
                            Details:
                            <br></br>
                            <textarea className='chat-new-category-modal-details-text-area'
                                value={newCategoryDetails}
                                onChange={(e) => setNewCategoryDetails(e.target.value)}
                                placeholder="Enter category details"
                            />
                        </label>
                        <div className="chat-new-category-modal-actions">
                            <button className='chat-new-category-modal-actions-cancel' onClick={() => setShowNewCategoryInput(false)}>Cancel</button>
                            <button className='chat-new-category-modal-actions-submit' onClick={handleAddNewCategory}>Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(ChatContainer);
