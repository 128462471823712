import React, { useState, useEffect } from 'react';
import './FormFlowsGenerator.css';
import FormFlowsScreens from './FormFlowsScreens';
import FormFlowsEditContent from './FormFlowsEditContent';
import FormFlowsPreview from './FormFlowsPreview';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Assuming you're using Axios
import { ElevatorSharp } from '@mui/icons-material';
import ConfirmationDialog from '../ConfirmationDialog';
import LoadingOverlay from '../LoadingOverlay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const FormFlowsGenerator = (currentUser) => {
  console.log(currentUser);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const isEditing = location?.state?.flow ? true : false;
  const queryFlowName = queryParams.get('flowName');
  const flowName = isEditing ? location.state?.flow?.flow?.Name : queryFlowName;
  const templateType = queryParams.get('templateType');
  const templateName = queryParams.get('templateName');

 const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openConfirmationErrorMessage, setOpenConfirmationErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  console.log(templateType);
  console.log(templateName);
  const [isTemplate , setIsTemplate] = useState(templateType === 'Template' ? true: false);
  console.log(isTemplate);
  console.log(location?.state);
  const templateJsonParsed = location?.state?.selectedTemplateJsonParsed || null;
 
  console.log(templateJsonParsed);
  const querySelectedCategory = isEditing ? location?.state?.flow?.category : queryParams.get('selectedCategory');
  const selectedCategory = isEditing ? location?.state.flow?.category : querySelectedCategory;
  const getTypeIdFromTypeName = (typeName) => {
    switch (typeName) {
      case 'TextHeading':
        return 1.1;
      case 'TextSubheading':
        return 1.2;
      case 'TextCaption':
        return 1.3;
      case 'TextBody':
        return 1.4;
      case 'Image':
        return 2.1;
      case 'TextInput':
        return 3.1;
      case 'TextArea':
        return 3.2;
      case 'DatePicker':
        return 3.3;
      case 'RadioButtonsGroup':
        return 4.1;
      case 'CheckboxGroup':
        return 4.2;
      case 'Dropdown':
        return 4.3;
      case 'OptIn':
        return 4.4;
      case 'PhotoPicker':
        return 5.1
        case 'DocumentPicker':
        return 5.2
      case 'Footer':
        return 0;
      default:
        return null; // If no match is found, return null or a fallback value
    }
  };
  
  const getContentByTypeId = (typeId, child) => {
    console.log(child);
    switch (typeId) {
      case 1.1:
      case 'TextHeading': // Large Heading
        return child.text;

      case 1.2:
      case 'TextSubheading': // Small Heading
        return child.text;

      case 1.3:
      case 'TextCaption': // Caption
        return child.text;

      case 1.4:
      case 'TextBody': // Body
        return child.text;

      case 2.1:
      case 'Image': // Image
        return {
          src: child.src,
          height: child.height || 340
        };

      case 3.1:
      case 'TextInput': // Short Answer (TextInput)
        return {
          label: child.label,
          instruction: child.instruction || ''
        };

      case 3.2:
      case 'TextArea': // Paragraph (TextArea)
        return {
          label: child.label,
          instruction: child.instruction || ''
        };

      case 3.3:
      case 'DatePicker': // Date Picker (DatePicker)
        return {
          label: child.label,
          instruction: child.instruction || ''
        };

      case 4.4:
      case 'OptIn': // OptIn
        return child.label;
      default:
        return null;
    }
  };
  const initialSendAllAnswersOnCompletion = isEditing
    ? location.state.flow?.sendAllAnswersOnCompletion === true
    : queryParams.get('sendAllAnswers') === 'true';

  const [sendAllAnswersOnCompletion, setSendAllAnswersOnCompletion] = useState(initialSendAllAnswersOnCompletion);
  const [isPublishModalOpen, setPublishModalOpen] = useState(false);

  // Fetch the template JSON if `templateType` is set to 'template'
  const [templateJson, setTemplateJson] = useState(null);



  const initialFlow = isEditing
    ? {
      id: location.state.flow?.flow?.Id || Date.now(),
      name: location.state.flow?.flow?.Name || flowName || 'Unnamed Flow',
      category: selectedCategory || 'Uncategorized',
      screens: location.state.flow?.flow?.screens.map(screen => ({
        id: screen.Id,
        title: screen.Title,
        isReadMoreScreen: screen?.isReadMoreScreen,
        content: screen.Layout?.Children?.map((child, index) => {
          return {
            id: `content_${index}`,
            type: child.typeId,
            text: child.text,
            label: child.label || 'Default Label',
            content: getContentByTypeId(child.typeId, child),
            instruction: child.instruction || '',
            src: child.src || null,
            height: child.height || 0,
            required: child.required || false,
            isRequired: child?.required,
            sendOnChange: child.sendOnChange || false,
            readMoreLink: child?.readMoreLink,
            'on-click-action': child?.['on-click-action'],
            isEdit: true,
            addScreen: child?.addScreen,
            options: child.Options ? child.Options.map(option => option.Title) : [],
            name: child.name || `Child_${index}`
          };
        }) || [],
        button: screen.Layout?.Children.find(child => child.type === 'Footer')?.label || 'Continue'
      })) || [],
      assets: {
        version: '3.1',
        screens: []
      }
    }
    : templateType === 'Template' && templateJsonParsed
      ? {
        id: Date.now(), // Unique ID for the flow
        name: templateJsonParsed?.Name || flowName || 'Unnamed Flow', // Flow name from the template or fallback
        category: selectedCategory || 'Uncategorized', // Use category from the template or fallback
        screens: templateJsonParsed.screens?.map(screen => ({
          id: screen.id || Date.now(), // Screen ID or generate one
          title: screen.title || 'Untitled Screen', // Screen title or fallback
          content: screen.layout?.children[0]?.children?.map((child, index) => ({
            id: `content_${index}`, // Unique ID for the content item
            type: getTypeIdFromTypeName(child?.type), // Type of content (e.g., TextSubheading, Image, etc.)
            label: child.label || 'Default Label', // Fallback label for inputs
            content: getContentByTypeId(child?.type, child),
            src: child.src || null, // Image source for image-type elements
            height: child.height || 0, // Height for image-type elements
            required: child.required || false, // Whether the field is required
            readMoreLink: child.readMoreLink || false, // Read more link, if applicable
            'on-click-action': child?.['on-click-action'], // Click actions for buttons or other interactive elements
            options: child.Options
              ? child.Options.map(option => option.Title)
              : (child['data-source']
                ? child['data-source'].map(option => option.title || option.Title)
                : []),
          })) || [], // Map over the content items in the screen
          button: screen.layout?.children.find(child => child.type === 'Footer')?.label || 'Continue' // Button label (e.g., Continue)
        })) || [], // Use screens from the template or fallback
        assets: {
          version: '3.1',
          screens: [] // Screens for any assets (if applicable)
        }
      }
      : {
        id: Date.now(),
        name: flowName || 'Unnamed Flow',
        category: selectedCategory || 'Uncategorized',
        screens: [{ id: Date.now(), title: 'Screen 1', content: [], button: 'Continue' }],
        assets: {
          version: '3.1',
          screens: []
        }
      };

  console.log("Initial Flow:", initialFlow);

  const [flow, setFlow] = useState(initialFlow);

  const [selectedScreen, setSelectedScreen] = useState(initialFlow.screens[0]);
  console.log(selectedScreen);
  const getPayloadForScreen = (screens, currentScreenIndex) => {
    let payload = {};
    console.log(screens);
    // Iterate over all previous screens up to the current one
    for (let i = 0; i <= currentScreenIndex; i++) {
      const screen = screens[i];
      // const screenIdFormatted = screen.title.replace(/\s+/g, '_').toUpperCase();
      let screenIndexFormatted = i+1;
      const screenIdFormatted = convertNumberToOrdinal(screenIndexFormatted);

      screen.content.forEach((contentItem, contentIndex) => {
        console.log(contentItem?.type);
        if (
          contentItem?.type !== 1.1 &&
          contentItem?.type !== 1.2 &&
          contentItem?.type !== 1.3 &&
          contentItem?.type !== 1.4 &&
          contentItem?.type !== 2.1
          &&
          contentItem?.type !== 0
          
        ) {
          const uniqueName = contentItem.name
            ? contentItem.name.replace(/\s+/g, '_').replace(/-/g, '_') + `_S${i}_C${contentIndex}`
            : `field_S${i}_C${contentIndex}`;
      
          // If sendOnChange is true, include in the payload
          if (contentItem.sendOnChange === true) {
            // For current screen, it comes from the form object
            if (i === currentScreenIndex) {
              payload[uniqueName] = `\${form.${uniqueName}}`;
            } 
            // For previous screens, it comes from screen data
            else {
              payload[`screen_${i}_${uniqueName}`] = `\${screen.${screenIdFormatted}.${uniqueName}}`;
            }
          }
        }
      });
      
        
    }
    return payload;
  };


  console.log(flow);
  const updateAssets = (screens) => {
    const updatedAssets = {
      ...flow.assets,
      screens: screens.map((screen, index) => {
        const screenId = screen.title.replace(/\s+/g, '_').toUpperCase();
        const isLastScreen = index === screens.length - 1;

        return {
          id: screenId,
          title: screen.title,
          data: {},
          terminal: isLastScreen,
          layout: {
            type: 'SingleColumnLayout',
            children: [{
              type: 'Form',
              name: 'flow_path',
              children: screen.content.map((contentItem, contentIndex) => mapContentToObject(contentItem, contentIndex, index)).concat({
                type: 'Footer',
                label: screen.button || 'Submit',
                // 'on-click-action': {
                //   name: isLastScreen ? 'complete' : 'navigate',
                //   next: !isLastScreen
                //     ? {
                //       type: 'screen',
                //       name: screens[index + 1].title.replace(/\s+/g, '_').toUpperCase(),
                //     }
                //     : null,
                //   payload: getPayloadForScreen(screens, index) // Pass payload for current and previous screens
                // }
                'on-click-action': isLastScreen
                ? {
                    // For the last screen, keep the "complete" action as before
                    name: 'complete',
                    payload: getPayloadForScreen(screens, index)
                  }
                : {
                  name: 'data_exchange', // Action for non-last screens
                  // next: {
                  //   type: 'screen',
                  //   name: screenId, // Set the next screen ID for navigation
                  //   version: "3.0",
                  //   action: "data_exchange",
                  //   screen: replaceDigitsWithWords(screen.title).replace(/\s+/g, '_').toUpperCase(), // Current screen ID
                  //   data: getDataForScreen(flow.screens, index) // Generate data only for this screen
                  // },
                  payload: {
                    version : "3.0",
                    action : "data_exchange",
                    screen : screenId,
                    data: getDataForScreen(flow.screens, index)
                  }
                    // For screens that are not the last, set to "data_exchange"
                    // name: 'data_exchange',
                    // payload: {
                    //   version: "3.0",
                    //   action: "data_exchange",
                    //   screen: screenId, // current screen ID
                    //   data: getDataForScreen(screens, index) // Generate `data` only for this screen
                    // }
                  }
              })
            }]
          }
        };
      })
    };

    return updatedAssets;
  };
  const getDataForScreen = (screens, currentIndex) => {
    let data = {};

    // Get the current screen
    const currentScreen = screens[currentIndex];
  
    // Format the screen ID from the title
    let screenIndexFormatted = currentIndex + 1;
    const screenIdFormatted = convertNumberToOrdinal(screenIndexFormatted);

    // Check if the current screen has content
    if (currentScreen.content) {
        currentScreen.content.forEach((contentItem, contentIndex) => {
            // Only include relevant content items
            if (
                currentScreen?.isReadMoreScreen !== true &&
                contentItem?.type !== 1.1 &&
                contentItem?.type !== 1.2 &&
                contentItem?.type !== 1.3 &&
                contentItem?.type !== 1.4 &&
                contentItem?.type !== 2.1
                &&
                contentItem?.type !== 0
            ) {
                // Check if the name already has `_S${currentIndex}_C${contentIndex}` to prevent duplication
                const suffix = `_S${currentIndex}_C${contentIndex}`;
                const contentName = contentItem.name?.includes(suffix)
                    ? contentItem.name
                    : `${contentItem.name?.replace(/\s+/g, '_').replace(/-/g, '_') || `field`}${suffix}`;
  
                // Add to the data object with a placeholder format
                data[`${contentName}`] = `\${screen.${screenIdFormatted}.form.${contentName}}`;
            }
        });
    }

    return data;
};

  
  const handleAddScreen = () => {
    const newScreen = {
      id: Date.now(),
      title: `Screen ${flow.screens.length + 1}`,
      content: [],
      button: 'Continue'
    };
    const updatedScreens = [...flow.screens, newScreen];
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };

    setFlow(updatedFlow);
    setSelectedScreen(newScreen);
  };

  const handleSelectScreen = (screenId) => {
    setSelectedScreen(flow.screens.find(screen => screen.id === screenId));
  };

  const handleContentChange = (screenId, updatedContent) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, content: updatedContent } : screen
    );


    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };

    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleButtonChange = (screenId, updateButton) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, button: updateButton } : screen
    );
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };
    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };
  console.log(flow);
  const handleTitleChange = (screenId, updatedTitle) => {
    const updatedScreens = flow.screens.map(screen =>
      screen.id === screenId ? { ...screen, title: updatedTitle } : screen
    );
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };
    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.find(screen => screen.id === screenId));
  };

  const handleRemoveScreen = (screenId) => {
    const updatedScreens = flow.screens.filter(screen => screen.id !== screenId);
    const updatedFlow = {
      ...flow,
      screens: updatedScreens,
      assets: updateAssets(updatedScreens)
    };
    setFlow(updatedFlow);
    setSelectedScreen(updatedScreens.length > 0 ? updatedScreens[0] : null);
  };


  const getDataForNextScreen = (screens, currentScreenIndex) => {
    let accumulatedData = {};

    // Collect data from all previous screens up to the current one
    for (let i = 0; i < currentScreenIndex; i++) {
      const screen = screens[i];
      screen.content.forEach((contentItem, contentIndex) => {
        const contentName = contentItem.name
          ? contentItem.name.replace(/\s+/g, '_') + contentIndex
          : `field_${contentIndex}`;

        if (contentItem?.sendOnChange) {
          // Add data in the required format for the next screen
          accumulatedData[contentName] = {
            type: typeof contentItem.content === 'string' ? 'string' : 'array',
            "__example__": contentItem.content || ""
          };
        }
      });
    }

    return accumulatedData;
  };
  const generateUniqueName = (type, index) => {
    return `${type}_${index}_${Math.random().toString(36).substr(2, 6)}`;
  };

  const handleConfirmFormFlowClick = () => {
    setOpenConfirmation(true);
};

const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
};

const handleCloseConfirmationErrorMessage = () => {
    setOpenConfirmationErrorMessage(false);
};


const handleConfirmFailsTemplate = ()=>{
    navigate('/FormFlows')
}

  const handleSubmit = () => {
    setOpenConfirmation(false);
        setLoading(true); // Start loading
        setErrorMessage(''); // Clear any previous errors
    const routingModel = {};

    const Flow = {
      Name: flow.name,
      Version: "5.0",
      DataApiVersion: "3.0",
      RoutingModel: routingModel,
      Screens: flow.screens.map((screen, index) => {
        const isLastScreen = index === flow.screens.length - 1 || flow.screens[index + 1].isReadMoreScreen;

        // Get the next screen's ID for navigation
        const nextScreenId = !isLastScreen
          ? flow.screens[index + 1]?.title.replace(/\s+/g, '_').toUpperCase()
          : null;

        const nextScreenTitle = flow.screens[index + 1]?.title?.replace(/\s+/g, '_')?.toUpperCase();
        let screenIndexFormatted = index+1;
        console.log(nextScreenId);
        // nextScreenTitle = nextScreenTitle?.title?.replace(/\s+/g, '_')?.toUpperCase();
        return {
          id: screen.isReadMoreScreen
            ? screen.title.replace(/\s+/g, '_').toUpperCase() + '_' + numberToWord(index)
            : convertNumberToOrdinal(screenIndexFormatted),
          title: screen.title,
          isReadMoreScreen: screen?.isReadMoreScreen || false,
          // Conditionally include terminal property
          ...(screen.isReadMoreScreen ? {} : { terminal: isLastScreen }),
          data: {}, // Include the accumulated data in the 'data' field
          layout: {
            type: "SingleColumnLayout",
            children: [
              ...screen.content
                .filter(contentItem => contentItem.type !== 0) // Filter out items with type 0
                .map((contentItem, contentIndex) =>
                  mapContentToObject(contentItem, contentIndex, index, screen, nextScreenTitle)
                ),
              // Only add footer if the screen is not a read more screen
              ...(screen.isReadMoreScreen ? [] : [{
                type: 'Footer',
                label: screen.button,
                'on-click-action': isLastScreen
                ? {
                    name: 'complete', // Action for the last screen
                    payload: getPayloadForLastScreen(flow.screens) // Payload specific to the last screen
                  }
                : {
                    name: 'data_exchange', // Action for non-last screens
                    // next: {
                    //   type: 'screen',
                    //   name: nextScreenId, // Set the next screen ID for navigation
                    //   version: "3.0",
                    //   action: "data_exchange",
                    //   screen: replaceDigitsWithWords(screen.title).replace(/\s+/g, '_').toUpperCase(), // Current screen ID
                    //   data: getDataForScreen(flow.screens, index) // Generate data only for this screen
                    // },
                    payload: {
                      version : "3.0",
                      action : "data_exchange",
                      screen : convertNumberToOrdinal(screenIndexFormatted),
                      data: getDataForScreen(flow.screens, index)
                    }
                  }
                // 'on-click-action': {
                //   name: isLastScreen ? 'complete' : 'data_exchange', // Use 'data_exchange' for non-last screens
                //   ...(isLastScreen ? {} : { 
                //     next: { 
                //       type: "screen", 
                //       name: nextScreenId 
                //     } 
                //   }), // Only include next if not the last screen
                //   payload: isLastScreen
                //     ? getPayloadForLastScreen(flow.screens) // For the last screen, use a complete payload
                //     : {
                //         version: "3.0",
                //         action: "data_exchange",
                //         screen: replaceDigitsWithWords(screen.title).replace(/\s+/g, '_').toUpperCase(), // Current screen ID
                //         data: getDataForScreen(flow.screens, index) // Generate data only for this screen
                //       }
                // },
                // 'on-click-action': {
                //   name: isLastScreen ? 'complete' : 'navigate',
                //   ...(isLastScreen ? {} : { next: { type: "screen", name: nextScreenId } }), // Only include next if not the last screen
                //   payload: isLastScreen
                //     ? getPayloadForLastScreen(flow.screens) // For the last screen, use a complete payload
                //     : getPayloadForScreen(flow.screens, index), // For non-terminal screens, use incremental payload
                // },
              }]),
            ],
          },
        };
      }),

    };

    const formFlowData = {
      flowName: flow.name,
      Id: isEditing ? location.state.flow.flow.Id : null, // Include flowId if editing
      category: selectedCategory,
      sendAllAnswersOnCompletion: sendAllAnswersOnCompletion,
      flow: Flow,
      organization: currentUser.currentUser.organization,
      endpoint: isEditing ? location?.state?.flow?.endpoint : queryParams.get('endpointUrl'),
      WriteDataWebhook: isEditing ? location?.state?.flow?.WriteDataWebhook : queryParams.get('writeAnswerEndpoint'),
      GetDataWebhook: isEditing ? location?.state?.flow?.GetDataWebhook : queryParams.get('getDataEndpoint'),
      endpointType: isEditing ? location?.state?.flow?.endpointType : queryParams.get('endpointType'),
      ...(isEditing ? {} : { createdById: currentUser?.currentUser?.userId }), // Add createdById only if not editing
      modifiedById :currentUser?.currentUser?.userId,
    };
    console.log(formFlowData);
    const requestPayload = {
      organization: currentUser.currentUser.organization,
      formFlowId: isEditing ? location.state.flow.flow.Id : null, // Add formFlowId here
      formFlow: formFlowData // Include formFlowData
    };

    console.log('Submitting Request Payload:', JSON.stringify(requestPayload, null, 2));

    // axios.post('https://gambot.azurewebsites.net/api/webhooks/CreateFormFlow', requestPayload)
    const apiEndpoint = isEditing
      ? 'https://gambot.azurewebsites.net/api/webhooks/UpdateFormFlow'
      : 'https://gambot.azurewebsites.net/api/webhooks/CreateFormFlow';

    axios.post(apiEndpoint, requestPayload)
      .then(response => {
        console.log('Flow created successfully:', response.data);
        setLoading(false); // Stop loading
        if(response?.data?.success  === true){
          navigate('/FormFlows'); // Redirect to templates page

      }
      else{
          setErrorMessage(response?.data?.Message);
          setOpenConfirmationErrorMessage(true);
      }
        //navigate('/FormFlows'); // Use the correct path to your FormFlows page
      })
      .catch(error => {
        setErrorMessage(error);
        setOpenConfirmationErrorMessage(true);
        console.error('Error creating flow:', error);
    });
  };


  function convertNumberToOrdinal(number) {
    console.log(number);
    switch (number) {
      case 1: return "FIRST_SCREEN";
      case 2: return "SECOND_SCREEN";
      case 3: return "THIRD_SCREEN";
      case 4: return "FOURTH_SCREEN";
      case 5: return "FIFTH_SCREEN";
      case 6: return "SIXTH_SCREEN";
      case 7: return "SEVENTH_SCREEN";
      case 8: return "EIGHTH_SCREEN";
      case 9: return "NINTH_SCREEN";
      case 10: return "TENTH_SCREEN";
      // Add more cases if needed
      default: return `SCREEN_${number}`;
    }
  }
  function replaceDigitsWithWords(title) {
    const digitToOrdinalWordMap = {
      1: 'FIRST',
      2: 'SECOND',
      3: 'THIRD',
      4: 'FOURTH',
      5: 'FIFTH',
      6: 'SIXTH',
      7: 'SEVENTH',
      8: 'EIGHTH',
      9: 'NINTH',
      0: 'TENTH'
    };
  
    // Match "Screen X" format and replace "X" with ordinal word
    return title.replace(/SCREEN (\d+)/i, (match, digit) => {
      console.log(`Matched: ${match}, Digit: ${digit}`);  // Log the matched portion and the digit
      console.log(`Replacing with: ${digitToOrdinalWordMap[digit]}_SCREEN`);  // Log what it will be replaced with
      return `${digitToOrdinalWordMap[digit]}_SCREEN`;
    });
  }
  
  
  
  
  function numberToWord(num) {
    const ones = [
      '', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'
    ];

    // Validate input
    if (num < 1 || num > 9) {
      return 'Input should be a number between 1 and 9';
    }

    return ones[num];
  }


  const getPayloadForLastScreen = (screens) => {
    let payload = {};

    // Iterate over all screens and generate the payload keys
    screens.forEach((screen, screenIndex) => {
        let screenIndexTemp = screenIndex + 1;
        const screenIdFormatted = convertNumberToOrdinal(screenIndexTemp);

        // Check if `screen.content` exists
        if (screen.content) {
            screen.content.forEach((contentItem, contentIndex) => {
                if (
                    screen?.isReadMoreScreen !== true &&
                    contentItem?.type !== 1.1 &&
                    contentItem?.type !== 1.2 &&
                    contentItem?.type !== 1.3 &&
                    contentItem?.type !== 1.4 &&
                    contentItem?.type !== 2.1
                    &&
                contentItem?.type !== 0
                ) {
                    // Check if the name already has `_S${screenIndex}_C${contentIndex}` to prevent duplication
                    const suffix = `_S${screenIndex}_C${contentIndex}`;
                    const contentName = contentItem.name?.includes(suffix)
                        ? contentItem.name
                        : `${contentItem.name?.replace(/\s+/g, '_').replace(/-/g, '_') || `field`}${suffix}`;

                    // Set the payload keys to match the unique name format
                    payload[`screen_${screenIdFormatted}_${contentName}`] = `\${screen.${screenIdFormatted}.form.${contentName}}`;
                }
            });
        } else {
            console.warn(`No content found for screen: ${screen.title}`);
        }
    });

    return payload;
};



  const mapContentToObject = (contentItem, contentIndex, screenIndex, screen, nextScreenId) => {
    console.log(nextScreenId);
    const incrementedScreenIndex = screenIndex + 1;
    console.log(incrementedScreenIndex);
    const baseName = (contentItem.name || `content_${screenIndex}_${contentIndex}`).replace(/\s+/g, '_');

    // Generate a unique name only if `name` doesn’t already have `_S${screenIndex}_C${contentIndex}`
    const uniqueName = baseName.includes(`_S${screenIndex}_C${contentIndex}`)
        ? baseName
        : `${baseName}_S${screenIndex}_C${contentIndex}`;
    let contentObject = {};
    // const uniqueName = contentItem.name
    //   ? contentItem.name.replace(/\s+/g, '_').replace(/-/g, '_') + `_S${screenIndex}_C${contentIndex}`
    //   : `field_S${screenIndex}_C${contentIndex}`;
    console.log(contentItem);
    // Map content types correctly
    switch (contentItem.type) {
      case 1.1: // Large Heading
        contentObject = { type: 'TextHeading', text: contentItem.content, typeId: 1.1 || '' };
        break;
      case 1.2: // Small Heading
        contentObject = { type: 'TextSubheading', text: contentItem.content, typeId: 1.2 || '' };
        break;
      case 1.3: // Caption
        contentObject = { type: 'TextCaption', text: contentItem.content, typeId: 1.3 || '' };
        break;
      case 1.4: // Body
        contentObject = { type: 'TextBody', text: contentItem.content, typeId: 1.4 || '' };
        break;
      case 2.1: // Image
        contentObject = {
          type: 'Image',
          src: isEditing
            ? contentItem?.src ? `${contentItem.content?.src}` : ''
            : contentItem.content?.src ? `${contentItem?.content?.src}` : '',
          height: contentItem.content?.height || 340,
          'scale-type': contentItem.content?.['scale-type'] || 'contain',
          name: uniqueName,  // Use the generated unique name here.
          typeId: 2.1
        };

        break;
      case 3.1: // TextInput
        contentObject = {
          type: 'TextInput',
          label: contentItem?.content?.label || 'Label',
          required: contentItem.isRequired || false,
          'input-type': contentItem?.content?.type || 'text',
          'helper-text': contentItem?.content?.instruction || "",
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here   
          typeId: 3.1,
          instruction: contentItem?.content?.instruction || ""
        };
        break;
      case 3.2: // TextArea
        contentObject = {
          type: 'TextArea',
          label: contentItem?.content?.label || 'Label',
          required: contentItem.isRequired || false,
          'helper-text': contentItem?.content?.instruction || "",
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 3.2,
          instruction: contentItem?.content?.instruction || ""
        };
        break;
      case 3.3: // DatePicker
        contentObject = {
          type: 'DatePicker',
          label: contentItem?.content?.label || 'Label',
          required: contentItem?.isRequired || false,
          'helper-text': contentItem?.content?.instruction || "",
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 3.3,
          instruction: contentItem?.content?.instruction || ""
        };
        break;
      case 4.1: // RadioButtonsGroup
        contentObject = {
          type: 'RadioButtonsGroup',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 4.1
        };
        break;
      case 4.2: // CheckboxGroup
        contentObject = {
          type: 'CheckboxGroup',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 4.2
        };
        break;
      case 4.3: // Dropdown
        contentObject = {
          type: 'Dropdown',
          label: contentItem.label || 'Label',
          required: contentItem.isRequired || false,
          'Options': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          'data-source': contentItem.options?.map((option, idx) => ({ id: `${idx}_${option}`, title: option })) || [],
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 4.3
        };
        break;
      case 4.4: // OptIn
        contentObject = {
          type: 'OptIn',
          label: contentItem?.content || 'Label',
          readMoreLink: contentItem?.readMoreLink || false,
          readMoreText: contentItem?.readMoreText || '',
          required: contentItem?.isRequired || false,
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 4.4,
          "on-click-action": {
            "name": "navigate", // or "complete" based on your logic
            "payload": {},
            "next": {
              "name": nextScreenId + '_' + numberToWord(incrementedScreenIndex), // Ensure this uses the incremented index
              "type": "screen"
            }
          }
        };
        case 5.1: // PhotoPicker
        contentObject = {
          type: 'PhotoPicker',
          label: contentItem?.content?.label || 'Label',
          type: 'PhotoPicker',
          name: 'photo_picker',
          "min-uploaded-photos": 1,
          "max-uploaded-photos": 10,
          "max-file-size-kb": 10240 ,
          description : contentItem?.content?.description || 'description',
          readMoreLink: contentItem?.readMoreLink || false,
          readMoreText: contentItem?.readMoreText || '',
          required: contentItem?.isRequired || false,
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 5.1,
          
        };
        break;
        case 5.2: // DocumentPicker
        contentObject = {
          type: 'DocumentPicker',
          label: contentItem?.content?.label || 'Label',
          description : contentItem?.content?.description || 'description',
          readMoreLink: contentItem?.readMoreLink || false,
          readMoreText: contentItem?.readMoreText || '',
          required: contentItem?.isRequired || false,
          sendOnChange: contentItem?.sendOnChange || false,
          name: uniqueName,  // Use the generated unique name here
          typeId: 5.2,
          name: 'document_picker',
          "min-uploaded-documents": 1,
          "max-uploaded-documents": 10,
          "max-file-size-kb": 10240 ,
          "allowed-mime-types": [
            "image/jpeg",
            "image/avif",
            "image/gif",
            "image/heic",
            "image/heif",
            "image/png",
            "image/jpeg",
            "image/webp",
            "text/plain",
            "video/mp4",
            "video/mpeg",
            "application/pdf",
            "application/msword",
            "application/vnd.ms-excel",
            "application/vnd.ms-powerpoint",
            "application/vnd.oasis.opendocument.presentation",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/x-7z-compressed",
            "application/zip"
          ],
        };
        break;
      default:
        contentObject = { type: '', text: '', required: contentItem.isRequired || false };
    }

    return contentObject;
  };
  console.log('my flow', flow);
  const handleBackClick = () => {
    navigate('/FormFlows'); // Change this to the desired route
  };

  return (
    <div className="form-flows-generator-container">
      <LoadingOverlay loading={loading} /> {/* Loading overlay */}
            <ConfirmationDialog
                open={openConfirmation}
                onClose={handleCloseConfirmation}
                onConfirm={handleSubmit}
                message={`Are you sure you want to ${isEditing ? "Update" : "Create"} this form flow?`}
                actionType="Confirm"
            />
      <div className="form-flows-header">
          <IconButton onClick={handleBackClick} aria-label="back" style={{ color: 'var(--LogoColor)' }}>
            <ArrowBackIcon />
          </IconButton>
        <div className="flow-name-category">
          <h2>{flow.name} - {flow.category}</h2>
        </div>
        <div className="form-flows-generator-container-save-button-container">
           {/* Open confirmation dialog on click */}
           <button onClick={handleConfirmFormFlowClick} className="form-flows-generator-container-save-button">
            {isEditing ? 'Update' : 'Save'}
          </button>
        
        </div>
      </div>
      <div className='form-flows-body'>
        <FormFlowsScreens
          flow={flow}
          onAddScreen={handleAddScreen}
          onSelectScreen={handleSelectScreen}
          onRemoveScreen={handleRemoveScreen}
        />
        <FormFlowsEditContent
          selectedScreen={selectedScreen}
          onContentChange={handleContentChange}
          onTitleChange={handleTitleChange}
          onButtonChange={handleButtonChange}
          currentUser={currentUser}
          isEditing={isEditing}
          flow={flow} // Pass flow as a prop
          setFlow={setFlow} // Pass setFlow as a prop
          updateAssets={updateAssets} // Ensure this is passed
          isTemplate={isTemplate}

        />
        <FormFlowsPreview selectedScreen={selectedScreen} />
      </div>
    </div>
  );
};

export default FormFlowsGenerator;
