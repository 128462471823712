import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-timezone-select';
import './Scheduled.css';

const Scheduled = ({ onChange, initialValue, initialConfig }) => {
    const [scheduleType, setScheduleType] = useState('once');
    const [startDate, setStartDate] = useState(new Date());
    const [time, setTime] = useState('10:00');
    const [interval, setInterval] = useState('Minute');
    const [intervalNumber, setIntervalNumber] = useState(1);
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        if (initialValue) {
            const initialDate = new Date(initialValue);
            setStartDate(initialDate);
        }
    }, [initialValue]);

    const handleDateChange = (date) => {
        setStartDate(date);
        const formattedDate = formatDateTime(date);
        onChange({ scheduleType, date: formattedDate, interval, intervalNumber, timezone });
    };

    const handleTimeChange = (e) => {
        setTime(e.target.value);
        const date = new Date(startDate);
        const [hours, minutes] = e.target.value.split(':');
        date.setHours(hours);
        date.setMinutes(minutes);
        handleDateChange(date);
    };

    const handleIntervalChange = (e) => {
        setInterval(e.target.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval: e.target.value, intervalNumber, timezone });
    };

    const handleIntervalNumberChange = (e) => {
        setIntervalNumber(e.target.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval, intervalNumber: e.target.value, timezone });
    };

    const handleScheduleTypeChange = (e) => {
        setScheduleType(e.target.value);
        onChange({ scheduleType: e.target.value, date: formatDateTime(startDate), interval, intervalNumber, timezone });
    };

    const handleTimezoneChange = (tz) => {
        setTimezone(tz.value);
        onChange({ scheduleType, date: formatDateTime(startDate), interval, intervalNumber, timezone: tz.value });
    };

    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    return (
        <div className='scheduled-container'>
            <div className='schedule-type-selector'>
                <label>
                    Schedule Type:
                    <select value={scheduleType} onChange={handleScheduleTypeChange}>
                        <option value="once">Occurrence Once</option>
                        <option value="repeated">Repeated</option>
                    </select>
                </label>
            </div>

            <div className='scheduled-starting'>
                <label>
                    Start Date and Time:
                    <DatePicker
                        selected={startDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeIntervals={30}
                        timeFormat='HH:mm'
                    />
                    <input
                        type="time"
                        value={time}
                        onChange={handleTimeChange}
                        className='time-picker'
                    />
                </label>
            </div>

            <div className='scheduled-timezone'>
                <label>Time Zone:</label>
                <Select value={timezone} onChange={handleTimezoneChange} />
            </div>

            {scheduleType === 'repeated' && (
                <div className='scheduled-repeat'>
                    <label>
                        Repeat Every:
                        <div className='scheduled-input'>
                            <input
                                type="number"
                                value={intervalNumber}
                                onChange={handleIntervalNumberChange}
                                min={1}
                            />
                        </div>

                        <div className='scheduled-interval'>
                            <label>
                                Interval Unit:
                                <select value={interval} onChange={handleIntervalChange}>
                                    <option value="Second">Second</option>
                                    <option value="Minute">Minute</option>
                                    <option value="Hour">Hour</option>
                                    <option value="Day">Day</option>
                                    <option value="Week">Week</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            </label>
                        </div>
                    </label>
                </div>
            )}
        </div>
    );
};

export default Scheduled;



// import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './Scheduled.css';

// const Scheduled = ({ onChange, initialValue ,initialConfig}) => {
//     const [scheduleType, setScheduleType] = useState('once');
//     const [startDate, setStartDate] = useState(new Date());
//     const [time, setTime] = useState('10:00');
//     const [interval, setInterval] = useState('Minute');
//     const [intervalNumber, setIntervalNumber] = useState(1);

//     useEffect(() => {
//         if (initialValue) {
//             const initialDate = new Date(initialValue);
//             setStartDate(initialDate);
//         }
//     }, [initialValue]);

//     const handleDateChange = (date) => {
//         setStartDate(date);
//         const formattedDate = formatDateTime(date);
//         onChange({ scheduleType, date: formattedDate, interval, intervalNumber });
//     };

//     const handleTimeChange = (e) => {
//         setTime(e.target.value);
//         const date = new Date(startDate);
//         const [hours, minutes] = e.target.value.split(':');
//         date.setHours(hours);
//         date.setMinutes(minutes);
//         handleDateChange(date);
//     };

//     const handleIntervalChange = (e) => {
//         setInterval(e.target.value);
//         onChange({ scheduleType, date: formatDateTime(startDate), interval: e.target.value, intervalNumber });
//     };

//     const handleIntervalNumberChange = (e) => {
//         setIntervalNumber(e.target.value);
//         onChange({ scheduleType, date: formatDateTime(startDate), interval, intervalNumber: e.target.value });
//     };

//     const handleScheduleTypeChange = (e) => {
//         setScheduleType(e.target.value);
//         onChange({ scheduleType: e.target.value, date: formatDateTime(startDate), interval, intervalNumber });
//     };

//     const formatDateTime = (date) => {
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const year = date.getFullYear();
//         const hours = String(date.getHours()).padStart(2, '0');
//         const minutes = String(date.getMinutes()).padStart(2, '0');
//         const seconds = String(date.getSeconds()).padStart(2, '0');
//         return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
//     };

//     return (
//         <div className='scheduled-container'>
//             <div className='schedule-type-selector'>
//                 <label>
//                     Schedule Type:
//                     <select value={scheduleType} onChange={handleScheduleTypeChange}>
//                         <option value="once">Occurrence Once</option>
//                         <option value="repeated">Repeated</option>
//                     </select>
//                 </label>
//             </div>

//             <div className='scheduled-starting'>
//                 <label>
//                     Start Date and Time:
//                     <DatePicker
//                         selected={startDate}
//                         onChange={handleDateChange}
//                         dateFormat="dd/MM/yyyy HH:mm"
//                         showTimeSelect
//                         timeIntervals={30}
//                         timeFormat='HH:mm'
//                     />
//                     <input
//                         type="time"
//                         value={time}
//                         onChange={handleTimeChange}
//                         className='time-picker'
//                     />
//                 </label>
//             </div>

//             {scheduleType === 'repeated' && (
//                 <div className='scheduled-repeat'>
//                     <label>
//                         Repeat Every:
//                         <div className='scheduled-input'>
//                             <input
//                                 type="number"
//                                 value={intervalNumber}
//                                 onChange={handleIntervalNumberChange}
//                                 min={1}
//                             />
//                         </div>

//                         <div className='scheduled-interval'>
//                             <label>
//                                 Interval Unit:
//                                 <select value={interval} onChange={handleIntervalChange}>
//                                     <option value="Second">Second</option>
//                                     <option value="Minute">Minute</option>
//                                     <option value="Hour">Hour</option>
//                                     <option value="Day">Day</option>
//                                     <option value="Week">Week</option>
//                                     <option value="Month">Month</option>
//                                     <option value="Year">Year</option>
//                                 </select>
//                             </label>
//                         </div>
//                     </label>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Scheduled;