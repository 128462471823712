import React from 'react';
import './ScreenAnswersModal.css';

const ScreenAnswersModal = ({ screen, onClose }) => {
    const handleOverlayClick = (e) => {
        if (e.target.className === 'screen-answers-modal') {
            onClose(); // Close the modal if the overlay (background) is clicked
        }
    };

    return (
        <div className="screen-answers-modal" onClick={handleOverlayClick}>
            <div className="screen-answers-modal-content">
                <h2>Answers for {screen.ScreenId}</h2>
                <button className="screen-answers-close-button" onClick={onClose}>X</button>
                <div className="screen-answers-answers-list">
                    {Object.entries(screen.Answers).map(([question, answer], idx) => (
                        <p key={idx}><strong>{question}:</strong> {answer}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScreenAnswersModal;
