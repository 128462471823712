import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import LookUp from '../../../components/Lookup';
import Select from 'react-select';

const TemplatesButtonClickedHandler = ({ organization, onTemplatesChange, initialTemplates = [], initialButtons = [] }) => {
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState(initialTemplates);
  const [templateButtons, setTemplateButtons] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState(initialButtons);
  console.log(selectedTemplates);
  const fetchTemplates = useCallback(async () => {
    try {
      const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllTemplates', {
        organization: organization
    });
      const approvedTemplates = response.data.filter(template => template.status === "APPROVED");
      setAllTemplates(approvedTemplates);
      console.log("All templates fetched:", approvedTemplates);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  }, [organization]);

  const fetchInitialButtons = useCallback(async () => {
    if (initialTemplates.length > 0) {
      const selectedTemplateIds = initialTemplates.map(option => option.value);

      try {
        const buttonPromises = selectedTemplateIds.map(templateId =>
          axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById', {
            organizationId: organization,
            templateId
          })
        );

        const buttonResponses = await Promise.all(buttonPromises);
        const buttons = buttonResponses
          .flatMap(response => response.data.components)
          .filter(component => component.type === 'BUTTONS')
          .flatMap(component => component.buttons);

        setTemplateButtons(buttons);

        const mappedInitialButtons = initialButtons.map(button => {
          const matchedButton = buttons.find(b => b.text === button.value);
          return matchedButton ? { value: matchedButton.text, label: matchedButton.text } : button;
        });

        setSelectedButtons(mappedInitialButtons);
        console.log("Initial buttons fetched:", buttons);
      } catch (error) {
        console.error('Error fetching initial buttons:', error);
      }
    }
  }, [initialTemplates, initialButtons, organization]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    fetchInitialButtons();
  }, [fetchInitialButtons]);

  const handleTemplateChange = async (selectedOptions) => {
    setSelectedTemplates(selectedOptions);
    const selectedTemplateIds = selectedOptions.map(option => option.value);

    try {
      const buttonPromises = selectedTemplateIds.map(templateId =>
        axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetTemplateById', {
          organizationId: organization,
          templateId
        })
      );

      const buttonResponses = await Promise.all(buttonPromises);
      const buttons = buttonResponses
        .flatMap(response => response.data.components)
        .filter(component => component.type === 'BUTTONS')
        .flatMap(component => component.buttons);

      setTemplateButtons(buttons);
      setSelectedButtons([]);  // Reset selected buttons when templates change
      onTemplatesChange(selectedOptions, []);
      console.log("Buttons fetched:", buttons);
    } catch (error) {
      console.error('Error fetching buttons:', error);
    }
  };

  const handleButtonChange = (selectedOptions) => {
    setSelectedButtons(selectedOptions);
    onTemplatesChange(selectedTemplates, selectedOptions);
    console.log("Buttons selected:", selectedOptions);
  };
  console.log(selectedButtons);
  console.log(selectedTemplates);

  return (
    <div className="templates-button-clicked-handler">
      <LookUp
        options={allTemplates.map(template => ({ value: template.Id, label: template.name }))}
        onChange={handleTemplateChange}
        placeholder='Select Templates'
        value={selectedTemplates}
        isMulti={true}
      />
      {templateButtons.length > 0 && (
        <div>
          <h3>Select Buttons</h3>
          <Select
            isMulti
            options={templateButtons.map(button => ({ value: button.text, label: button.text }))}
            onChange={handleButtonChange}
            value={selectedButtons}
          />
        </div>
      )}
    </div>
  );
};

export default TemplatesButtonClickedHandler;
