import React , {useEffect} from 'react';

const FormFlowsScreens = ({ flow, onAddScreen, onSelectScreen, onRemoveScreen }) => {
  console.log(flow);
  
  return (
    <div className="FormFlowsScreens-section">
      <button onClick={onAddScreen}>+ Add new</button>
      <div className="FormFlowsScreens-list">
        {flow?.screens && flow.screens.length > 0 ? (
          flow.screens.map((screen) => (
            <div key={screen.id} className="screen-item">
              <span onClick={() => onSelectScreen(screen.id)}>
                {screen.title || 'Untitled Screen'}
              </span>
              {!screen?.isReadMoreScreen && (
                <button onClick={() => onRemoveScreen(screen.id)}>X</button>
              )}
            </div>
          ))
        ) : (
          <div>No screens available</div> // Fallback if no screens
        )}
      </div>
    </div>
  );
};

export default FormFlowsScreens;
