import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import './ChatMessage.css';

const ChatMessage = ({ message, time, sender, from, to, id, orgazanizationNumber, userName, messageType, onStarToggle, isStarred ,createdOn , currentUser}) => {
    console.log(createdOn);
    console.log(currentUser);
    const [starred, setStarred] = useState(isStarred || false);
    console.log(id);
    useEffect(() => {
        // Log message details for debugging purposes
        console.log('Message:', message);
    }, [message, orgazanizationNumber, from, userName, time]);

    const formatTimestamp = (timestampString) => {
        const timestamp = new Date(timestampString);
        const day = timestamp.getDate().toString().padStart(2, '0');
        const month = (timestamp.getMonth() + 1).toString().padStart(2, '0');
        const year = timestamp.getFullYear().toString();
        const hours = timestamp.getHours().toString().padStart(2, '0');
        const minutes = timestamp.getMinutes().toString().padStart(2, '0');
        const seconds = timestamp.getSeconds().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };
    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';
      
        try {
          return new Intl.DateTimeFormat('en-GB', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          }).format(new Date(utcDate));
        } catch (error) {
          console.error('Error formatting date:', error, 'Timezone:', timezone);
          return 'Invalid date';
        }
    };
    
    const handleStarToggle = () => {
        setStarred(!starred);
        onStarToggle(id?.messageId, !starred); // Pass message ID and new star status back to parent
    };

    return (
        <div className='chat-message'
            style={{
                alignSelf: from === orgazanizationNumber ? 'flex-end' : 'flex-start',
                backgroundColor: from === orgazanizationNumber ? '#dcf8c6' : '#fff'
            }}
        >
            <div className='chat-message-text'>
                <p>{message}</p>
            </div>
            <div className='chat-message-date'>
                <p>{createdOn ? formatToUserTimezone(createdOn , currentUser?.timeZone) : 'No Time Available'}</p>
            </div>
            <div className='chat-message-actions'>
                {starred ? (
                    <StarIcon onClick={handleStarToggle} style={{ color: 'gold' }} />
                ) : (
                    <StarBorderIcon onClick={handleStarToggle} />
                )}
            </div>
            {from === orgazanizationNumber && (
                <div className='chat-message-sender'>
                    <p>{userName}</p>
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
