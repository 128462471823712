import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css'

function UserProfile({ name, lastMessage, email, time, contact, onClick }) { // Remove photoURL prop
    const navigate = useNavigate();

    const goToContact = (contactPhoneNumber, name) => {
        if (contactPhoneNumber) {
            onClick(contact);
            navigate(`/${contactPhoneNumber}/${name}`);
        }
    };

    // Get the first letter of the name to use as the placeholder
    const firstLetter = name ? name.charAt(0).toUpperCase() : '?';

    return (
        <div className='user-profile' onClick={() => goToContact(contact.phoneNumber, name)}>
            <div className='user-image'>
                <div className='initial-placeholder'>{firstLetter}</div>
            </div>
            <div className='user-info'>
                <p className='user-name'>{name}</p>
                {lastMessage && (<p className='user-lastmessage'>{lastMessage + ' ' + time}</p>)}
            </div>
        </div>
    );
}

export default UserProfile;
