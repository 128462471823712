import React from 'react';
import './TextAreaWithCounter.css';

const TextAreaWithCounter = ({ value, onChange, maxLength, placeholder }) => {
  return (
    <div className="textarea-container">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxLength}
        placeholder={placeholder}
        className="textarea"
      />
      <div className="character-counter">
        {value.length}/{maxLength}
      </div>
    </div>
  );
};

export default TextAreaWithCounter;
