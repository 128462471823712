import React, { useState, useEffect } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './Templates.css';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import ConfirmationDialog from '../ConfirmationDialog';

const Templates = (currentUser) => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]); // State for templates data
  const [openDialog, setOpenDialog] = useState(false); // State for template details dialog
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State for the selected template
  const [templateName, setTemplateName] = useState(''); // State for the template name
  const [status, setStatus] = useState(''); // State for the template status
  const [templateType, setTemplateType] = useState(''); // State for the template type


  useEffect(() => {
    const fetchData = async () => {
      let orgID = currentUser.currentUser.organization;
      let timeZone = currentUser?.currentUser?.timeZone;
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/Webhooks/GetAllTemplates', {
          organization: orgID
          
        });
        const sortedTemplates = response.data
        .sort((a, b) => new Date(b.modifiedOn) - new Date(a.modifiedOn)) // Sort by modifiedOn descending
        .map(template => ({
          ...template,
          modifiedOn: formatToUserTimezone(template.modifiedOn, timeZone), // Convert modifiedOn to user timezone
          createdOn: formatToUserTimezone(template.createdOn, timeZone) // Convert createdOn to user timezone
        }));


      setTemplates(sortedTemplates);
        console.log(sortedTemplates);
        //setTemplates(sortedTemplates);

      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
// Function to convert UTC date to user time zone
const formatToUserTimezone = (utcDate, timezone) => {
  if (!utcDate) return 'No date available';

  try {
    return new Intl.DateTimeFormat('en-GB', {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(new Date(utcDate));
  } catch (error) {
    console.error('Error formatting date:', error, 'Timezone:', timezone);
    return 'Invalid date';
  }
};


  const handleDeleteClick = (template) => {
    setSelectedTemplate(template);
    setOpenDialog(true);
  };

  const handleEditClick = (template) => {
    console.log(template);
    // const thisTemplateQueryString = new URLSearchParams(template).toString();
    const thisTemplateQueryString = new URLSearchParams({
      "organization": currentUser.currentUser.organization,
      ...template,
      components: JSON.stringify(template.components)
    })
    navigate(`/UpdateTemplate/?${thisTemplateQueryString}`);
  };
  const handleConfirmDelete = () => {
    // Call your delete template API here with selectedTemplate.id
    var payload = {
      "organization": currentUser.currentUser.organization,
      "templateId": selectedTemplate.Id,
      "templateName": selectedTemplate.name,
    }
    console.log(payload);
    axios.post("https://gambot.azurewebsites.net/api/Webhooks/DeleteTemplateByID", payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
    setOpenDialog(false);
  };

  // Handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle template name change
  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  // Handle status change
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // Handle template type change
  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
  };

  const canEditTemplate = (template) => {
    if (!template?.modifiedOn || !template?.createdOn) {
      return { canEdit: true, remainingTime: null };
    }
  
    const now = new Date();
  
    // Helper function to parse "DD/MM/YYYY, HH:MM:SS" to a Date object with error handling
    const parseDate = (dateString) => {
      if (!dateString || typeof dateString !== 'string') return null;
  
      const [datePart, timePart] = dateString.split(', ');
      if (!datePart || !timePart) return null;
  
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
  
      if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return null;
      }
  
      return new Date(year, month - 1, day, hours, minutes, seconds);
    };
  
    const modifiedOn = parseDate(template.modifiedOn);
    const createdOn = parseDate(template.createdOn);
  
    if (!modifiedOn || !createdOn) {
      return { canEdit: true, remainingTime: null };
    }
  
    if (createdOn.getTime() === modifiedOn.getTime()) {
      return { canEdit: true, remainingTime: null };
    }
  
    const nextEditTime = new Date(modifiedOn.getTime() + 24 * 60 * 60 * 1000);
  
    if (now >= nextEditTime) {
      return { canEdit: true, remainingTime: null };
    }
  
    const remainingTime = nextEditTime - now;
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  
    return {
      canEdit: false,
      remainingTime: `${hours} hours and ${minutes} minutes`,
    };
  };
  
  return (
    <div className="template-list-container">
      <h1 className="template-list-header">Templates</h1>
      {/* Add a search input if needed */}
      {/* <input type="text" placeholder="Search Templates" className="search-input" /> */}
      <div className='template-list-table-conatiner'>
      <table className="template-list-table">
        <thead>
          <tr>
            <th>Template Name</th>
            <th>Template ID</th>
            <th>Status</th>
            <th>Last Modifide</th>
            <th>Template Type</th>            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {templates.map((template) => {
              const { canEdit, remainingTime } = canEditTemplate(template);
              return (
                <tr key={template.id}>
                  <td>{template.name}</td>
                  <td>{template.Id}</td>
                  <td>{template.status}</td>
                  <td>
                    {template.modifiedOn} by {template?.modifiedByName || 'Unknown'}
                  </td>
                  <td>{template.category}</td>
                  <td>
                    {canEdit ? (
                      <button
                        className="template-edit-button"
                        onClick={() => handleEditClick(template)}
                      >
                        Edit
                      </button>
                    ) : (
                      <span className="countdown">
                        You can edit this template in {remainingTime}
                      </span>
                    )}
                    <button
                      className="template-delete-button"
                      onClick={() => handleDeleteClick(template)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
      </table>
      </div>
      
      <ConfirmationDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete the flow: "${selectedTemplate?.name}"?`}
        actionType='Delete'
      />
      <div className='templates-action-buttons'>
        <Link to="/NewMessageTemplate">
          <button className="add-new-template-button">Add New Template</button>
        </Link>
      </div>
    </div>
  );
};

export default Templates;