import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, message, actionType }) => {
  const isDeleteAction = actionType === 'Delete';
  const actionText = isDeleteAction ? 'Delete' : 'Confirm';
  const dialogTitle = isDeleteAction ? 'Confirm Deletion' : 'Confirm Action';
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm}  color={isDeleteAction ? 'error' : 'success'}>{actionText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
