import React, { useState } from 'react';
import ScreenAnswersModal from './ScreenAnswersModal';
import './ScreenProgressIndicator.css';

const ScreenProgressIndicator = ({ totalScreens, screenProgress }) => {
    const [selectedScreen, setSelectedScreen] = useState(null);

    const handleScreenClick = (screen) => {
        setSelectedScreen(screen); // Set the selected screen to open in modal
    };

    const closeModal = () => {
        setSelectedScreen(null); // Close modal by clearing selected screen
    };

    return (
        <div className="screen-progress-indicator">
            {Array.from({ length: totalScreens }).map((_, index) => {
                const screen = screenProgress ? screenProgress[index] : null;
                const isCompleted = screen?.IsCompleted || false;

                return (
                    <button
                        key={index}
                        className={`screen-indicator ${isCompleted ? 'completed' : 'incomplete'}`}
                        onClick={() => handleScreenClick(screen)}
                        disabled={!screen} // Disable if no data is available for the screen
                    >
                        {isCompleted ? `Screen ${index + 1}` : `Screen ${index + 1}`}
                    </button>
                );
            })}
            {selectedScreen && (
                <ScreenAnswersModal
                    screen={selectedScreen}
                    onClose={closeModal}
                />
            )}
        </div>
    );
};

export default ScreenProgressIndicator;
