import React, { useState , useEffect} from 'react';
import './NewFormFlows.css';
import { BrowserRouter as Router, Route, Link,useLocation,useNavigate, json } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { TbTemplate } from "react-icons/tb";
import { BiCustomize } from "react-icons/bi";


const NewFormFlow = ({ currentUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Initialize state variables
  const [flowName, setFlowName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [endpointType, setEndpointType] = useState('Gambot'); // Default to Gambot
  const [endpointUrl, setEndpointUrl] = useState(''); // External endpoint URL
  const [sendAllAnswers, setSendAllAnswers] = useState(true); // Send all answers: true or false
  const [writeAnswerEndpoint, setWriteAnswerEndpoint] = useState(''); // Write answer webhook URL
  const [getDataEndpoint, setGetDataEndpoint] = useState(''); // Get data webhook URL
  console.log(location?.state?.flow);
  // New state for flow type (Custom/Template) and template selection
  const [flowType, setFlowType] = useState('Custom'); // Default to Custom
  const [selectedTemplate, setSelectedTemplate] = useState(null); // For template selection
  const [templates, setTemplates] = useState([]); // State to hold fetched templates
  const [selectedTemplateJsonParsed , setSelectedTemplateJsonParsed] = useState(null);
 

  const isEditing = location?.state?.flow ? true : false;
  const header = isEditing 
    ? `Update Form Flow: ${flowName || 'Unnamed Flow'}` 
    : 'Create Form Flow';  // Extract flow data from location.state if in edit mode
  useEffect(() => {
    if (location.state && location.state.flow) {
      const flow = location.state.flow;

      setFlowName(flow.flowName || ''); // Set flowName
      setSelectedCategory(flow.category || ''); // Set selectedCategory
      setEndpointType(flow.endpointType || 'Gambot'); // Set endpointType
      setEndpointUrl(flow.endpointUrl || ''); // Set endpointUrl
      setSendAllAnswers(flow.sendAllAnswersOnCompletion);
      setWriteAnswerEndpoint(flow.WriteDataWebhook || ''); // Set writeAnswerEndpoint
      setGetDataEndpoint(flow.GetDataWebhook || ''); // Set getDataEndpoint
    }
    // Fetch the list of templates from the backend API
    const fetchTemplates = async () => {
      try {
        const response = await axios.post('https://gambot.azurewebsites.net/api/webhooks/GetAllFormFlowTemplatesJson');
    
        const templateList = response.data.map(template => ({
          json: template.json,        // Include the JSON content
          label: template.Name,        // Template name for display
          id: template.Id,             // Document ID
          path: template.path,         // File path
          fileName: template.FileName  // Optional filename if it exists
        }));
    
        setTemplates(templateList); // Assuming setTemplates is your state setter
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, [location.state, currentUser]);
  const categories = [
    { value: 'SIGN_UP', label: 'SIGN_UP' },
    { value: 'SIGN_IN', label: 'SIGN_IN' },
    { value: 'APPOINTMENT_BOOKING', label: 'APPOINTMENT_BOOKING' },
    { value: 'LEAD_GENERATION', label: 'LEAD_GENERATION' },
    { value: 'CONTACT_US', label: 'CONTACT_US' },
    { value: 'CUSTOMER_SUPPORT', label: 'CUSTOMER_SUPPORT' },
    { value: 'SURVEY', label: 'SURVEY' },
    { value: 'OTHER', label: 'OTHER' }
  ];
  console.log(sendAllAnswers);
  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value); // Correctly set the selected category
  };
  const handleFlowNameChange = (event) => {
    setFlowName(event.target.value);
  };

  const handleEndpointTypeChange = (event) => {
    setEndpointType(event.target.value);
  };

  const handleEndpointUrlChange = (event) => {
    setEndpointUrl(event.target.value);
  };

  const handleSendAllAnswersChange = (event) => {
    setSendAllAnswers(event.target.value === 'true'); // Convert string to boolean
  };
  console.log(sendAllAnswers);
  const handleWriteAnswerEndpointChange = (event) => {
    setWriteAnswerEndpoint(event.target.value); // Write answer webhook URL
  };
  const handleGetDataEndpointChange = (event) => {
    setGetDataEndpoint(event.target.value); // Get data webhook URL
  };
  const handleFlowTypeChange = (type) => {
    setFlowType(type);
    if (type === 'Custom') {
      setSelectedTemplate(null); // Reset template if Custom is selected
    }
  };

  const handleTemplateChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedTemplate(selectedOption);
    
    // Use lowercase 'json' based on your data structure
    const parsedJson = selectedOption?.json ? JSON.parse(selectedOption.json) : null;
  
    setSelectedTemplateJsonParsed(parsedJson);
  };

  const mode = "Create";
  const queryParams = new URLSearchParams({
    mode,
    flowName,
    selectedCategory: selectedCategory?.value || '',
    endpointType,
    endpointUrl: endpointType === 'External' ? endpointUrl : '',
    sendAllAnswers,
    writeAnswerEndpoint,
    getDataEndpoint,
    organization: currentUser.organization,
    templateType: selectedTemplate ? 'Template' : 'Custom', // Check if the user selected a template
    templateJson: selectedTemplate ? selectedTemplate.value : '' // Pass the selected template JSON if template is selected
  }).toString();

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const queryParams = new URLSearchParams({
      flowName,
      selectedCategory,
      endpointType,
      endpointUrl: endpointType === 'External' ? endpointUrl : '',
      sendAllAnswers,
      writeAnswerEndpoint,
      getDataEndpoint,
      organization: currentUser.organization,
      templateType: selectedTemplate ? 'Template' : 'Custom', // Pass template type if selected
      templateName: selectedTemplate ? selectedTemplate.label : '' // Pass the template name, not the JSON
    }).toString();
  
    // Check if in edit mode or creating new
    if (location.state && location.state.flow) {
      const flow = location.state.flow;
      console.log(flow);
      const thisFlowQueryString = new URLSearchParams({
        organization: currentUser.organization,
        ...flow,
        components: JSON.stringify(flow.components)
      });
  
      navigate(`/UpdateFormFlow`, { state: { flow } }); // Navigate to update flow
    } else {
      // Creating new flow
      navigate(`/FormFlowsGenerator?${queryParams}`, {
        state: {
          selectedTemplateJsonParsed // Pass the large JSON in state instead of query string
        }
      }); }
  };
  
  const variables = '$variables';
  return (
    <div className="new-form-flow-container">
      <h1>{header}</h1>
      <form className='new-form-flow-form' onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter form flow name..."
          value={flowName}
          onChange={handleFlowNameChange}
          className="form-flow-name-input"
        />
        <div className="new-form-flow-form-category-selection">
        <Select
            options={categories}
            onChange={handleCategoryChange}
            placeholder="Select a category"
            value={categories.find(cat => cat.value === selectedCategory)} // Set the value to the selected category
          />
        </div>
        {/* Flow Type Selection */}
        <div className="new-form-flow-flow-type-selection">
        <label className='new-form-flow-flow-type-selection-label'>Flow Type?</label>
        <div className="new-form-flow-flow-type-icons">
          <div
            className={`new-form-flow-flow-type-icon ${flowType === 'Custom' ? 'selected' : ''}`}
            onClick={() => handleFlowTypeChange('Custom')}
          >
            <BiCustomize size={100} />
            <p>Custom</p>
          </div>
          <div
            className={`new-form-flow-flow-type-icon ${flowType === 'Template' ? 'selected' : ''}`}
            onClick={() => handleFlowTypeChange('Template')}
          >
            <TbTemplate size={100} />
            <p>Template</p>
          </div>
        </div>
      </div>

        {/* Template selection dropdown if "Template" is selected */}
        {flowType === 'Template' && (
          <div className="new-form-flow-template--selection">
            <label>Select Template:</label>
            <Select
  styles={{
    control: (base) => ({
      ...base,
      backgroundColor: 'white', // Background for the control (dropdown input)
      borderColor: 'var(--LogoColor)', // You can adjust this to match your theme
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: 'white', // Background for the dropdown options
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected
        ? 'lightblue' // Background for selected option
        : state.isFocused
        ? 'lightgray' // Background for focused option
        : 'white', // Default background for unselected options
      color: state.isSelected ? 'black' : 'black', // Ensure text is readable
    }),
  }}
  options={templates}
  onChange={handleTemplateChange}
  placeholder="Choose a template"
  value={templates.find((template) => template.id === selectedTemplate?.id)}
/>

          
          </div>
        )}
        {/* Endpoint Type Selection */}
        <div className="new-form-flow-endpoint-type-selection">
          <label>Endpoint Type:</label>
          <select value={endpointType} onChange={handleEndpointTypeChange} className="form-flow-endpoint-type-select">
            <option value="Gambot">Gambot</option>
            <option value="External">External</option>
          </select>
        </div>

        {/* External Endpoint URL input */}
        {endpointType === 'External' && (
          <input
            type="text"
            placeholder="Enter external endpoint URL..."
            value={endpointUrl}
            onChange={handleEndpointUrlChange}
            className="form-flow-endpoint-input"
          />
        )}

        {/* Send All Answers on Flow Completion Dropdown */}
        <div className="new-form-flow-send-all-answers-dropdown">
          <label>Send all answers on flow completion:</label>
          <select value={sendAllAnswers.toString()} onChange={handleSendAllAnswersChange} className="form-flow-send-all-answers-select">
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
        </div>

        {/* Write Answer Webhook URL */}
        <div className="new-form-flow-write-answer-webhook">
          <label>Webhook Address To Write Answers:</label>
          <input
            type="text"
            placeholder="Enter webhook to write answers..."
            value={writeAnswerEndpoint}
            onChange={handleWriteAnswerEndpointChange}
            className="form-flow-webhook-input"
          />
        </div>

        {/* Get Data Webhook URL */}
        <div className="new-form-flow-get-data-webhook">
          <label>Webhook Address To Get Data For {variables}:</label>
          <input
            type="text"
            placeholder="Enter webhook to fetch data..."
            value={getDataEndpoint}
            onChange={handleGetDataEndpointChange}
            className="form-flow-webhook-input"
          />
        </div>

        <br />
        <div className="new-form-flow-action-buttons">
          <Link to="/FormFlows">
            <button className="new-form-flow-action-buttons-cancel" type="button">
              Cancel
            </button>
          </Link>
          <button className="new-form-flow-action-buttons-continue" type="submit">
            Continue
          </button>
        
        </div>
      </form>
    </div>
  );
};

export default NewFormFlow;
