import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableHead, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import './CampaignResult.css';

const CampaignResult = ({ open, onClose, campaignResult, currenUser }) => {
    console.log(campaignResult);
    const [openRecipientList, setOpenRecipientList] = useState(false);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClose = () => {
        onClose();
    };

    const handleOpenRecipientList = (recipients) => {
        setSelectedRecipients(recipients);
        setOpenRecipientList(true);
    };

    const handleCloseRecipientList = () => {
        setOpenRecipientList(false);
        setSearchQuery(''); // Clear search query when closing
    };

    const formatToUserTimezone = (utcDate, timezone) => {
        if (!utcDate) return 'No date available';
      
        try {
            return new Intl.DateTimeFormat('en-GB', {
                timeZone: timezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).format(new Date(utcDate));
        } catch (error) {
            console.error('Error formatting date:', error, 'Timezone:', timezone);
            return 'Invalid date';
        }
    };

    // Filter recipients based on search query
    const filteredRecipients = selectedRecipients.filter(recipient =>
        recipient?.PhoneNumber.includes(searchQuery)
    );
    console.log(campaignResult);
    return (
        <>
            <Dialog
                className='campaignResult-container-dialog'
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle className='campaignResult-container-dialog-title'>Campaign Result</DialogTitle>
                <DialogContent className='campaignResult-container-dialog-content'>
                    {campaignResult && campaignResult.length > 0 ? (
                        <Table className='campaignResult-container-dialog-tbl'>
                            <TableHead className='campaignResult-container-dialog-tbl-tablehead'>
                                <TableRow>
                                    <TableCell>Runs At</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Runs By Name</TableCell>
                                    <TableCell>Recipient Number</TableCell>
                                    <TableCell>Sent</TableCell>
                                    <TableCell>Delivered</TableCell>
                                    <TableCell>Read</TableCell>
                                    <TableCell>Failed</TableCell>
                                    <TableCell>Press Button</TableCell>
                                    <TableCell>Button Pressed</TableCell>
                                    <TableCell>Reply</TableCell>
                                    <TableCell>Spam</TableCell>
                                    <TableCell>View Recipients</TableCell> {/* New column for View Recipients button */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {campaignResult.map((result, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{formatToUserTimezone(result.RunsAt, currenUser?.timeZone)}</TableCell>
                                        <TableCell>{result.Status}</TableCell>
                                        <TableCell>{result.RunsByName}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.TotalContactsNumber}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfSentMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfDeliveredMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfReadMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfFailureOutboundMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfClickButton}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfButtonPressed}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfReplyMessage}</TableCell>
                                        <TableCell>{result.CampaignResultSummary?.NumberOfIsSpam}</TableCell>
                                        <TableCell>
                                            <Button 
                                                variant="outlined" 
                                                onClick={() => handleOpenRecipientList(result.CampaignRecipientData)}
                                            >
                                                View Recipients
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <p>No campaign results available.</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Recipient List Dialog */}
            <Dialog
                open={openRecipientList}
                onClose={handleCloseRecipientList}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Recipient List</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Search by Phone Number"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Message ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRecipients?.length > 0 ? (
                                filteredRecipients?.map((recipient, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{recipient?.PhoneNumber}</TableCell>
                                        <TableCell>{recipient?.Status}</TableCell>
                                        <TableCell>{recipient?.MessageId}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                                        No recipients found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRecipientList} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CampaignResult;
