import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ChatContainer from './ChatContainer';
import Sidebar from './Sidebar';
import './ChatPage.css';
import WebSocketInstance from './WebSocketInstance';
import axios from 'axios';

function ChatPage({ currentUser, signOut }) {
    const { contactphonenumber, name } = useParams();
    const decodedName = decodeURIComponent(name);
    const contactsWebSocketRef = useRef(null);
    const chatWebSocketRef = useRef(null);

    const [currentChatRecipient, setCurrentChatRecipient] = useState(() => {
        return localStorage.getItem('currentChatRecipient') || null;
    });
    const [contact, setContact] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(() => {
        const savedContact = JSON.parse(localStorage.getItem('selectedContact'));
        console.log("Loaded selectedContact from localStorage:", savedContact);
        return savedContact || null;
    });

    const checkContactExistsByPhoneNumber = async (phoneNumber) => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetContactObjById", {
                contactID: phoneNumber,
                organization: currentUser.organization,
            });
            return response.data || null;
        } catch (error) {
            console.error("Error checking contact existence:", error);
            return null;
        }
    };

    // Fetch and set the contacts list when the component mounts
    const fetchContacts = async () => {
        try {
            const response = await axios.post("https://gambot.azurewebsites.net/api/Webhooks/GetAllContactsByOrg", {
                organizationiD: currentUser.organization
            });
            const fetchedContacts = response.data || [];
            setContacts(fetchedContacts);
            console.log("Fetched contacts:", fetchedContacts);

            // If contactphonenumber is in URL, try to set the selected contact
            if (contactphonenumber) {
                const matchedContact = fetchedContacts.find(contact => contact.phoneNumber === contactphonenumber);
                if (matchedContact) {
                    setSelectedContact(matchedContact);
                    localStorage.setItem('selectedContact', JSON.stringify(matchedContact));
                }
            }
        } catch (error) {
            console.error("Error fetching contacts:", error);
        }
    };

    // Call fetchContacts when the component first mounts
    useEffect(() => {
        fetchContacts();
    }, [currentUser.organization]);

    // Fetch a specific contact if contactphonenumber exists but selectedContact isn't set
    useEffect(() => {
        const loadContactFromUrl = async () => {
            if (contactphonenumber && !selectedContact) {
                const contact = await checkContactExistsByPhoneNumber(contactphonenumber);
                if (contact) {
                    setSelectedContact(contact);
                    localStorage.setItem('selectedContact', JSON.stringify(contact));
                    console.log("Contact loaded from URL phone number:", contact);
                }
            }
        };
        loadContactFromUrl();
    }, [contactphonenumber, selectedContact]);

    // Initialize WebSocket for contacts when component mounts
    useEffect(() => {
        if (!contactsWebSocketRef.current && currentUser) {
            contactsWebSocketRef.current = new WebSocketInstance(currentUser.organization);
        }
    }, [currentUser]);

    // Initialize WebSocket for the chat recipient when `contactphonenumber` changes
    useEffect(() => {
        if (contactphonenumber && currentUser) {
            if (chatWebSocketRef.current) {
                chatWebSocketRef.current.close();
            }
            chatWebSocketRef.current = new WebSocketInstance(currentUser.organization, contactphonenumber);
            setCurrentChatRecipient(contactphonenumber);
        }
    }, [contactphonenumber, currentUser]);

    // Save the current chat recipient in local storage
    useEffect(() => {
        if (currentChatRecipient) {
            localStorage.setItem('currentChatRecipient', currentChatRecipient);
            console.log("Saved currentChatRecipient to localStorage:", currentChatRecipient);
        }
    }, [currentChatRecipient]);

    // Update contacts when they change
    const handleContactsUpdate = useCallback((fetchedContacts) => {
        setContacts(fetchedContacts);
        console.log("Contacts updated:", fetchedContacts);
    }, []);

    // Set the selected contact when clicked in the sidebar
    const handleContactClick = useCallback((contact) => {
        setSelectedContact(contact);
        setCurrentChatRecipient(contact.phoneNumber);
        // Save the selected contact in localStorage
        localStorage.setItem('selectedContact', JSON.stringify(contact));
        console.log("Selected contact saved to localStorage:", contact);
    }, []);

    return (
        <div className='chatpage'>
            <div className='chatpage-container'>
                <Sidebar
                    currentUser={currentUser}
                    signOut={signOut}
                    currentChatRecipient={currentChatRecipient}
                    webSocket={chatWebSocketRef.current}
                    onContactsUpdate={handleContactsUpdate}
                    onContactClick={handleContactClick}
                />
                <ChatContainer
                    currentUser={currentUser}
                    setCurrentChatRecipient={setCurrentChatRecipient}
                    webSocket={chatWebSocketRef.current}
                    selectedContact={selectedContact}
                />
            </div>
        </div>
    );
}

export default React.memo(ChatPage);
