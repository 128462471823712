import React, { useState } from 'react';
import './FormParam.css';

const FormParam = ({ onAddContent, selectedScreen }) => {
  // Define all possible options
  const [options] = useState([
    {
      id: 1,
      name: 'Text',
      subOptions: [
        { id: 1.1, name: 'Large Heading', namingType: 'TextHeading' },
        { id: 1.2, name: 'Small Heading', namingType: 'TextSubheading' },
        { id: 1.3, name: 'Caption', namingType: 'TextCaption' },
        { id: 1.4, name: 'Body', namingType: 'TextBody' },
      ],
    },
    {
      id: 2,
      name: 'Media',
      subOptions: [{ id: 2.1, name: 'Image', namingType: 'Image' }],
    },
    {
      id: 3,
      name: 'Text Answer',
      subOptions: [
        { id: 3.1, name: 'Short Answer', namingType: 'TextInput' },
        { id: 3.2, name: 'Paragraph', namingType: 'TextArea' },
        { id: 3.3, name: 'Date Picker', namingType: 'DatePicker' },
      ],
    },
    {
      id: 4,
      name: 'Selection',
      subOptions: [
        { id: 4.1, name: 'Single Choice', namingType: 'RadioButtonsGroup' },
        { id: 4.2, name: 'Multiple Choice', namingType: 'CheckboxGroup' },
        { id: 4.3, name: 'Dropdown', namingType: 'Dropdown' },
        { id: 4.4, name: 'Opt-in' },
      ],
    },
    {
      id: 5,
      name: 'Media Picker',
      subOptions: [
        { id: 5.1, name: 'Photo Picker', namingType: 'PhotoPicker' },
        { id: 5.2, name: 'Document Picker', namingType: 'DocumentPicker' },
        
      ],
    },
  ]);

  // Filter options based on whether the selected screen is a read-more screen
  const filteredOptions = selectedScreen?.isReadMoreScreen
    ? options.filter(option => option.id === 1 || option.id === 2) // Only show Text and Media options
    : options; // Show all options if not a read-more screen

  const [showOptions, setShowOptions] = useState(false);

  const handleOptionClick = () => {
    setShowOptions(true);
  };

  const handleSubOptionClick = (subOption, option) => {
    console.log(option);
    onAddContent(subOption, option.subOptions);
    setShowOptions(false);
  };

  return (
    <div className="form-param-container">
      <button onClick={handleOptionClick}>+ Add content</button>
      {showOptions && (
        <div className="options-list">
          {filteredOptions.map((option) => (
            <div key={option.id} className="option-item">
              {option.name}
              <div className="sub-options-list">
                {option.subOptions.map((subOption) => (
                  <div key={subOption.id} onClick={() => handleSubOptionClick(subOption, option)}>
                    {subOption.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormParam;
