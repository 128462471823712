import React from 'react';
import './FormFlowsPreview.css';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
const FormFlowsPreview = ({ selectedScreen }) => {
  if (!selectedScreen) return null;

  const renderPreviewContent = (contentItem) => {
    const { label, instruction } = contentItem.content || {};
   
    console.log(contentItem);
    switch (contentItem.type) {
      case 1.1: // Large Heading
      case 'TextHeading': // Large Heading
        return (
          <div key={contentItem.id}>
            <p style={{ fontWeight: 'bold', fontSize: '36px' }}>{contentItem.content}</p>
          </div>
        );
      case 1.2: // Small Heading
      case 'TextSubheading': // Small Heading
        return (
          <div key={contentItem.id}>
            <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{contentItem.content}</p>
          </div>
        );
      case 1.3: // Caption
      case 'TextCaption': // Caption
      case 1.4: // Body
      case 'TextBody': // Body
        return (
          <div
            key={contentItem.id}
            style={{ fontSize: contentItem.type === 1.3 ? '12px' : '14px' }}
          >
            {contentItem.content}
          </div>
        );
      case 2.1: // Image
      case 'Image': // Image
      return (
        <div key={contentItem.id} className='form-flows-preview-image-preview-container'>
                {contentItem?.isEdit ? (
        <img 
          src={`data:${contentItem?.content?.type};base64,${contentItem?.content?.src}`} 
          alt={contentItem.name} 
          style={{ height: `${contentItem?.height}px` }} 
        />
      ) : (
        <img 
          src={`data:${contentItem?.content?.type};base64,${contentItem?.content?.src?.src}`} 
          alt={contentItem.name} 
          style={{ height: `${contentItem?.content?.height}px` }} 
        />
      )}
        </div>
      );
      case 3.1: // Short Answer
      case 'TextInput': // Short Answer
        return (
          <div key={contentItem.id} className="form-flows-preview-short-answer-preview-container">
            <input
              type="text"
              placeholder={contentItem?.content?.label !== "" ? contentItem?.content?.label : 'Label'}
              readOnly  
            />
            <p className="form-flows-preview-short-answer-preview-container-instruction-p">
              {contentItem.content.instruction}
            </p>
          </div>
        );
      case 3.2: // Paragraph
      case 'TextArea': // Paragraph
        return (
          <div key={contentItem.id} className="FormFlowsPreview-paragraph-preview-container">
            <textarea
              placeholder={contentItem.content.label}
              readOnly
              maxLength={600}
              rows={6} // Adjust the number of rows to make the box larger
            />
            <p className='FormFlowsPreview-paragraph-preview-container-instruction-p'>{contentItem.content.instruction}</p>
          </div>
        );
      case 3.3: // Date Picker
      case 'DatePicker': // Date Picker
      return (
        <div key={contentItem.id} className='FormFlowsPreview-datePicker-preview-container'>
        <span>{contentItem?.content?.label}</span>
        <br />
        <input
          type="date"
          readOnly
          value={new Date().toISOString().split('T')[0]} // Set to today’s date in ISO format
          className="custom-date-input"
        />
        <p className='FormFlowsPreview-datePicker-preview-container-instruction'>{contentItem.content.instruction}</p>
      </div>
      );
      case 4.1: // Single Choice      
      case 'RadioButtonsGroup': // Single Choice
        return (
          <div key={contentItem.id} className='FormFlowsPreview-single-choice-preview-container'>
            <p className="FormFlowsPreview-single-choice-preview-container-title">{contentItem.content}</p>
            {contentItem?.options?.map((option, index) => (
              <div key={index} className="FormFlowsPreview-single-choice-preview-container-option-item">
                <label htmlFor={`option-${index}`} className="FormFlowsPreview-single-choice-preview-container-option-label">
                  <span className='FormFlowsPreview-single-choice-preview-container-option-label-span'>{option}</span>
                  <input
                    type="radio"
                    id={`option-${index}`}
                    name={`single-choice-${contentItem.id}`}
                    className="FormFlowsPreview-single-choice-preview-container-radio-input"
                  />
                </label>
              </div>  
            ))}
          </div>
        );
        case 4.2: // Multiple Choice
        case 'CheckboxGroup': // Multiple Choice
        return (
          <div key={contentItem.id} className='FormFlowsPreview-multiple-choice-preview-container'>
            <p className="FormFlowsPreview-multiple-choice-preview-container-title">{contentItem.name}</p>
            {contentItem?.options?.map((option, index) => (
              <div key={index} className="FormFlowsPreview-multiple-choice-preview-container-option-item">
                <label htmlFor={`option-${index}`} className="FormFlowsPreview-multiple-choice-preview-container-option-label">
                  <span className='FormFlowsPreview-multiple-choice-preview-container-option-label-span'>{option}</span>
                  <input
                    type="checkbox"
                    id={`option-${index}`}
                    name={`multiple-choice-${contentItem.id}`}
                    className="FormFlowsPreview-multiple-choice-preview-container-checkbox-input"
                  />
                </label>
              </div>
            ))}
          </div>
        );
        case 4.3: // Dropdown
        case 'Dropdown': // Dropdown
        return (
          <div key={contentItem.id} className="FormFlowsPreview-dropdown-preview-container">
            <label htmlFor={`dropdown-${contentItem.id}`} className="FormFlowsPreview-dropdown-preview-container-label">
              {contentItem.name}
            </label>
            <select 
              id={`dropdown-${contentItem.id}`} 
              name={`dropdown-${contentItem.id}`} 
              className="FormFlowsPreview-dropdown-preview-container-select" 
            >
              {contentItem?.options?.map((option, index) => (
                <option key={index} value={option} className="FormFlowsPreview-dropdown-preview-container-option">
                  {option}
                </option>
              ))}
            </select>
          </div>
        );
      
        case 4.4: // Opt-in (Preview Mode)
        case 'OptIn': // OptIn
        return (
          <div key={contentItem.id} className="FormFlowsPreview-optin-preview-container">
            <label className="FormFlowsPreview-optin-preview-label">
              <input type="checkbox" className="FormFlowsPreview-optin-checkbox" />
              <span>{contentItem.content}</span>
              {contentItem?.readMoreLink && (
              <a href="#" className="FormFlowsPreview-optin-read-more-link">{contentItem?.readMoreText ? contentItem?.readMoreText : 'Read More'}</a>
              )}
            </label>
          </div>
        );
        case 5.1: // Photo Picker
case 'PhotoPicker': // Photo Picker
  return (
    <div key={contentItem.id} className="form-flows-preview-photo-picker-container">
      <p className="form-flows-preview-photo-picker-label">{label || 'Upload photos'}</p>
      <p className="form-flows-preview-photo-picker-description">
        {contentItem?.content?.description || 'Please attach images about the received items'}
      </p>
      <p className="form-flows-preview-photo-picker-max-size">Add 1 to 10 photos. Max file size 10 MB.</p>
      <button className="form-flows-preview-media-picker-custom-button">
        <PhotoCameraIcon className='form-flows-preview-media-picker-icon' style={{ marginRight: '5px' }} /> {/* Photo icon */}
        Take photo
      </button>
    </div>
  );

case 5.2: // Document Picker
case 'DocumentPicker': // Document Picker
  return (
    <div key={contentItem.id} className="form-flows-preview-document-picker-container">
      <p className="form-flows-preview-document-picker-label">{label || 'Contract'}</p>
      <p className="form-flows-preview-document-picker-description">
        {contentItem?.content?.description || 'Attach the signed copy of the contract'}
      </p>
      <p className="form-flows-preview-document-picker-max-size">Max file size 1 MB.</p>
      <button className="form-flows-preview-media-picker-custom-button">
            <InsertDriveFileIcon className="form-flows-preview-media-picker-icon" />
            Upload document
          </button>
    </div>
  );
      default:
        return null;
    }
  };

  return (
    <div className="FormFlowsPreview-section">
      <h2 style={{ textAlign: 'center' }}>Preview</h2>
      <div className="FormFlowsPreview-content">
        <h3 style={{ textAlign: 'center' }}>{selectedScreen.title}</h3>
        {selectedScreen.content.map(renderPreviewContent)}
        <button className='FormFlowsPreview-section-continue-btn'>{selectedScreen.button}</button>
      </div>
    </div>
  );
};

export default FormFlowsPreview;
