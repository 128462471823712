// AllAnswersModal.js
import React from 'react';
import './AllAnswersModal.css';

const AllAnswersModal = ({ formData, onClose }) => {
    return (
        <div className="all-answers-modal" onClick={onClose}>
            <div className="all-answers-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>All Answers</h2>
                <button className="all-answers-modal-close-button" onClick={onClose}>X</button>
                <div className="all-answers-modal-answers-list">
                    {Object.entries(formData).map(([question, answer], idx) => (
                        <p key={idx}><strong>{question}:</strong> {answer}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AllAnswersModal;
